<template>
  <v-form
    ref="form"
    @submit.prevent="action === 'create' ? createitem() : edititem()"
  >
    <v-row>
      <v-col md="6" cols="12">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Fecha"
              readonly
              outlined
              :value="dateFormatted"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            locale="es-ES"
            color="primary"
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="6" cols="12">
        <v-textarea
          v-model="reason"
          outlined
          label="Motivo"
          rows="1"
          row-height="25"
        ></v-textarea>
      </v-col>

      <v-col md="4" cols="12">
        <v-text-field
          v-model="weight"
          type="number"
          :label="`Peso (${weightMeasure})`"
          @wheel="$event.target.blur()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col md="2" cols="12">
        <v-select
          v-model="weightMeasure"
          :items="[
            {
              text: 'lb',
              value: 'lb',
            },
            {
              text: 'kg',
              value: 'kg',
            },
          ]"
          label="Und. de peso"
          outlined
        ></v-select>
      </v-col>

      <v-col v-if="heightMeasure !== 'ft'" md="4" cols="12">
        <v-text-field
          v-model="height"
          type="number"
          :label="`Talla (${heightMeasure})`"
          @wheel="$event.target.blur()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col v-if="heightMeasure === 'ft'" md="2" cols="12">
        <v-text-field
          v-model="height"
          type="number"
          :label="`Talla (')`"
          placeholder="0'"
          @wheel="$event.target.blur()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col v-if="heightMeasure === 'ft'" md="2" cols="12">
        <v-text-field
          v-model="inchHeight"
          type="number"
          label='Talla (")'
          placeholder="0''"
          @wheel="$event.target.blur()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col md="2" cols="12">
        <v-select
          v-model="heightMeasure"
          :items="[
            {
              text: 'cm',
              value: 'cm',
            },
            {
              text: 'mt',
              value: 'mt',
            },
            {
              text: 'ft',
              value: 'ft',
            },
          ]"
          label="Und. de talla"
          outlined
        ></v-select>
      </v-col>
      <v-col md="6" cols="12">
        <v-text-field
          v-model="imc"
          type="text"
          label="IMC"
          readonly
          maxlength="11"
          :hint="imcInterpreter.text"
          persistent-hint
          :color="imcInterpreter.color"
          outlined
        ></v-text-field>
      </v-col>
      <v-col md="6" cols="12">
        <v-text-field
          v-model="glucoseTest"
          type="number"
          label="Glucotest"
          @wheel="$event.target.blur()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="FR"
          type="number"
          label="FR"
          @wheel="$event.target.blur()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="FC"
          type="number"
          label="FC"
          @wheel="$event.target.blur()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="temperature"
          type="number"
          label="Temparatura (°C)"
          @wheel="$event.target.blur()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="TA"
          type="text"
          label="TA"
          :rules="rules.TA"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="head"
          type="text"
          label="Cabeza"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="eyes"
          type="text"
          label="Ojos"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="nose"
          type="text"
          label="Nariz"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="ears"
          type="text"
          label="Oidos"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="neck"
          type="text"
          label="Cuello"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="chest"
          type="text"
          label="Torax"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="abdomen"
          type="text"
          label="Abdomen"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="genitals"
          type="text"
          label="Genitales"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="upperLimbs"
          type="text"
          label="Miembros superiores"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="lowerLimbs"
          type="text"
          label="Miembros inferiores"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="cardiopulmonary"
          type="text"
          label="Cardiopulmonar"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="neurological"
          type="text"
          label="Neurológico"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <div class="d-flex justify-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                icon
                v-bind="attrs"
                v-on="on"
                @click="showTemplates"
              >
                <v-icon>mdi-clipboard-text-multiple</v-icon>
              </v-btn>
            </template>
            <span class="caption">Seleccionar plantilla</span>
          </v-tooltip>
        </div>
        <VueEditor
          v-model="comment"
          placeholder="Desarrollo"
          :editorToolbar="[
            ['bold', 'italic', 'underline', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
            [{ color: [] }, { background: [] }],
          ]"
        />
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col>
        <v-btn
          small
          depressed
          color="primary"
          class="pa-5"
          type="submit"
          :loading="loading"
        >
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapMutations, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import { convertAndCalculateImc } from "@/helpers/common.js";

export default {
  name: "PhysicalActivityForm",
  props: ["action", "patient", "item", "field"],
  components: {
    VueEditor,
  },
  data() {
    return {
      id: "",
      weight: "",
      height: "",
      inchHeight: "",
      glucoseTest: "",
      FR: "",
      FC: "",
      TA: "",
      temperature: "",
      comment: "",
      time: "",
      date: "",
      reason: "",
      currentDisease: "",
      head: "",
      eyes: "",
      nose: "",
      ears: "",
      neck: "",
      chest: "",
      abdomen: "",
      genitals: "",
      upperLimbs: "",
      lowerLimbs: "",
      cardiopulmonary: "",
      neurological: "",
      weightMeasure: "lb",
      heightMeasure: "cm",
      loading: false,
      date: moment().startOf("day").toDate().toJSON().slice(0, 10),
      dateMenu: false,
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 50) || "Debe tener menos de 50 caracteres",
        ],
        TA: [
          (v) => {
            if (v) {
              if (v.length < 8) return true;
              return "Formato invalido";
            } else return true;
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["activities", "iconField"]),
    dateFormatted() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
    imc() {
      return convertAndCalculateImc({
        height: this.height,
        weight: this.weight,
        inchHeight: this.inchHeight,
        heightMeasure: this.heightMeasure,
        weightMeasure: this.weightMeasure,
      });
    },
    imcInterpreter() {
      if (this.imc === 0) {
        return { text: "", color: "dark_primary" };
      } else if (this.imc < 18.5) {
        return { text: "Bajo peso", color: "light-blue" };
      } else if (this.imc >= 18.5 && this.imc <= 25) {
        return { text: "Peso normal", color: "gray" };
      } else if (this.imc >= 25 && this.imc <= 30) {
        return { text: "Sobrepeso", color: "yellow" };
      } else if (this.imc >= 30 && this.imc <= 35) {
        return { text: "Obesidad 1", color: "deep-orange" };
      } else if (this.imc >= 35 && this.imc <= 40) {
        return { text: "Obesidad 2", color: "red" };
      } else if (this.imc >= 40) {
        return { text: "Obesidad 3", color: "red" };
      } else {
        return { text: "error", color: "red" };
      }
    },
  },
  methods: {
    ...mapMutations(["setAlert"]),
    showTemplates() {
      this.$emit("show-template-list", this.field);
    },
    setTemplateContent(content) {
      this.comment = content;
    },
    convertMeasures({ height, weight }) {
      const centimetersToMeters = height / 100;
      const pountsToKilo = weight / 2.2046;
      return { height: centimetersToMeters, weight: pountsToKilo };
    },
    async createitem() {
      this.loading = true;
      try {
        const itemToCreate = {
          weight: this.weight || 0,
          height: this.height || 0,
          glucoseTest: this.glucoseTest || 0,
          FR: this.FR || 0,
          FC: this.FC || 0,
          TA: this.TA || 0,
          temperature: this.temperature || 0,
          comment: this.comment,
          time: this.time,
          reason: this.reason,
          currentDisease: this.currentDisease,
          head: this.head,
          eyes: this.eyes,
          nose: this.nose,
          ears: this.ears,
          neck: this.neck,
          chest: this.chest,
          abdomen: this.abdomen,
          genitals: this.genitals,
          upperLimbs: this.upperLimbs,
          lowerLimbs: this.lowerLimbs,
          cardiopulmonary: this.cardiopulmonary,
          neurological: this.neurological,
          weightMeasure: this.weightMeasure,
          heightMeasure: this.heightMeasure,
          inchHeight: this.inchHeight || 0,
          patient: this.patient.id,
          date: moment(this.date),
        };
        const res = await axios.post("/api/medicalevaluation", itemToCreate);
        const { evaluation, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.activity = "";
        this.dedicatedTime = "";
        this.frequency = "";
        this.comment = "";
        this.$emit("created", evaluation);
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
    async edititem() {
      this.loading = true;
      try {
        const itemToUpdate = {
          id: this.id,
          weight: this.weight,
          height: this.height,
          glucoseTest: this.glucoseTest,
          FR: this.FR,
          FC: this.FC,
          TA: this.TA,
          temperature: this.temperature,
          comment: this.comment,
          time: this.time,
          reason: this.reason,
          currentDisease: this.currentDisease,
          head: this.head,
          eyes: this.eyes,
          nose: this.nose,
          ears: this.ears,
          neck: this.neck,
          chest: this.chest,
          abdomen: this.abdomen,
          genitals: this.genitals,
          upperLimbs: this.upperLimbs,
          lowerLimbs: this.lowerLimbs,
          cardiopulmonary: this.cardiopulmonary,
          neurological: this.neurological,
          weightMeasure: this.weightMeasure,
          heightMeasure: this.heightMeasure,
          inchHeight: this.inchHeight,
          date: moment(this.date),
        };
        const res = await axios.put(
          `/api/medicalevaluation/${this.id}`,
          itemToUpdate
        );
        const { evaluation, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$emit("updated", evaluation);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
  },
  watch: {},
  watch: {
    TA: function () {
      if (this.TA.length === 2 && this.TA[0] > 5) {
        this.TA = this.TA + "/";
      } else {
        if (
          this.TA.length === 3 &&
          this.TA[0] <= 5 &&
          !isNaN(parseInt(this.TA[0], 10))
        ) {
          this.TA = this.TA + "/";
        }
      }
    },
    item: {
      immediate: true,
      handler(value) {
        if (value) {
          this.id = value.id;
          this.weight = value.weight;
          this.height = value.height;
          this.glucoseTest = value.glucoseTest;
          this.FR = value.FR;
          this.FC = value.FC;
          this.TA = value.TA;
          this.temperature = value.temperature;
          this.comment = value.comment;
          this.time = value.time;
          this.reason = value.reason;
          this.currentDisease = value.currentDisease;
          this.head = value.head;
          this.eyes = value.eyes;
          this.nose = value.nose;
          this.ears = value.ears;
          this.neck = value.neck;
          this.chest = value.chest;
          this.abdomen = value.abdomen;
          this.genitals = value.genitals;
          this.upperLimbs = value.upperLimbs;
          this.lowerLimbs = value.lowerLimbs;
          this.cardiopulmonary = value.cardiopulmonary;
          this.neurological = value.neurological;
          this.weightMeasure = value.weightMeasure;
          this.heightMeasure = value.heightMeasure;
          this.inchHeight = value.inchHeight;
          this.date = value.date.slice(0, 10);
        }
      },
    },
  },
};
</script>
