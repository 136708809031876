var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Title',{attrs:{"title":'Iniciar consulta'}}),_c('div',{staticClass:"mb-8 d-flex"},[_c('v-tabs',{attrs:{"icons-and-text":"","height":"40","color":"secondary","slider-color":"secondary"},model:{value:(_vm.startAppointmentTab),callback:function ($$v) {_vm.startAppointmentTab=$$v},expression:"startAppointmentTab"}},[_c('v-tab',[_c('div',{staticClass:"caption font-weight-medium d-flex align-center"},[_c('div',[_vm._v("Nueva")])])]),_c('v-tab',[_c('div',{staticClass:"caption font-weight-medium d-flex align-center"},[_c('div',[_vm._v("Seleccionar existente")])])])],1)],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.startAppointmentTab === 0),expression:"startAppointmentTab === 0"}]},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.locations,"label":"Sede","item-value":"id","item-text":"name","outlined":""},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":[
            'Consulta',
            'Procedimiento',
            'Entrega de resultado o analítica',
            'Vacunación',
            'Chequeo rutinario',
          ],"label":"Motivo","outlined":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"pa-5",attrs:{"small":"","depressed":"","color":"primary","type":"submit","disabled":!_vm.reason,"loading":_vm.loading},on:{"click":_vm.createAppointment}},[_vm._v(" crear e Iniciar ")])],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.startAppointmentTab === 1),expression:"startAppointmentTab === 1"}]},[_c('v-data-table',{staticClass:"row-pointer mt-5",attrs:{"headers":[
        { text: 'Fecha', value: 'date' },
        { text: 'Sede', value: 'location' },
        { text: 'Motivo', value: 'reason' },
        {
          text: 'Hora estimada',
          value: 'estimatedTime',
          align: 'center',
        },
      ],"items":_vm.appointmentsToPick.filter((a) => a.status === 'en espera'),"items-per-page":_vm.appointmentsToPick.filter((a) => a.status === 'en espera').length,"item-key":"code","loading-text":"Cargando...","no-data-text":"Sin datos...","item-class":"text-center","disable-sort":"","hide-default-footer":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.date",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDate(props.item.date))+" ")]}},{key:"item.reason",fn:function(props){return [_vm._v(" "+_vm._s(props.item.reason ? props.item.reason : "-")+" ")]}},{key:"item.location",fn:function(props){return [_vm._v(" "+_vm._s(props.item.location ? props.item.location.name : "-")+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }