<template>
  <div>
    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Edad superior a los 70 años (5) </span>
      <span style="padding-left: 3px">{{ item.ageOver70 ? "[x]" : "[]" }}</span>
    </div>

    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Infarto a los 6 meses previos (10) </span>
      <span style="padding-left: 3px">{{
        item.heartAttackInThePrevious6Months ? "[x]" : "[]"
      }}</span>
    </div>

    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Galope diastólico PVY elevada (11) </span>
      <span style="padding-left: 3px">{{
        item.PVYelevation ? "[x]" : "[]"
      }}</span>
    </div>

    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Estenosis aórtica significativa (3) </span>
      <span style="padding-left: 3px">{{
        item.significantAorticStenosis ? "[x]" : "[]"
      }}</span>
    </div>

    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span
        >Ritmo distinto al sinusal o extrasístoles supraventriculares (7)
      </span>
      <span style="padding-left: 3px">{{
        item.differentRhythm ? "[x]" : "[]"
      }}</span>
    </div>

    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Más de cinco extrasístoles supraventriculares por minuto (7) </span>
      <span style="padding-left: 3px">{{
        item.moreThan5DifferentRhythmPerMinute ? "[x]" : "[]"
      }}</span>
    </div>

    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>{{ "PO2 < 60 o POCO2 > 50 Mmhg (3)" }} </span>
      <span style="padding-left: 3px">{{
        item.PO2MoreThan50Mmgh ? "[x]" : "[]"
      }}</span>
    </div>

    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span
        >{{
          "K < 3.0 o CO3H > 20 UREA > 50 CREAT > 3.0 AST elevada o Hepatopatía Crónica Paciente encamado por causa no cardíaca (3)"
        }}
      </span>
      <span style="padding-left: 3px">{{
        item.patientBedriddenForNonCardiacCause ? "[x]" : "[]"
      }}</span>
    </div>

    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>{{ "Cirugía Intraperitoneal, Intratoracica, Aorta (4)" }} </span>
      <span style="padding-left: 3px">{{
        item.aortaSurgery ? "[x]" : "[]"
      }}</span>
    </div>

    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>{{ "Cirugía de urgencia (5)" }} </span>
      <span style="padding-left: 3px">{{
        item.emergencySurgery ? "[x]" : "[]"
      }}</span>
    </div>

    <p>
      Total puntos acumulados:
      <span>
        {{ totalPoints }} | {{ cardioAnalysisInterpreter(totalPoints).text }}
      </span>
    </p>
    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 20px"
    >
      <span>Desarrollo: </span>
    </div>
    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      v-html="item.comment || '-'"
    ></div>
  </div>
</template>

<script>
import {
  calculateCardioPoints,
  cardioPointsInterpreter,
} from "../../../helpers/medical";

export default {
  name: "CardioAnalysisPrint",
  props: ["item", "off", "hideEmptyValues"],
  computed: {
    totalPoints() {
      return calculateCardioPoints(this.item);
    },
  },
  methods: {
    cardioAnalysisInterpreter(value) {
      return cardioPointsInterpreter(value);
    },
  },
};
</script>
