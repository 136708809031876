<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <InfoItem label="No. partos vía vaginal" :val="item.childbirth_v" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="No. partos vía cesarea" :val="item.childbirth_c" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="No. de abortos" :val="item.abortion" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="No. total de embarazos" :val="totalChilbirth" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem
          label="Hipertensión gestacional"
          :val="item.gestationalHypertension"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem
          label="Diabetes gestacional"
          :val="item.gestationalDiabetes"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Método anticonceptivo" :val="item.contraceptive" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Condiciones" :val="conditions" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="caption pasive--text">Desarrollo</div>
        <div class="body-1" v-if="item.comment" v-html="item.comment"></div>
        <span v-else class="caption font-weight-medium light_secondary--text"
          >-</span
        >
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn @click="clickEdit" text icon rounded>
        <v-icon small>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-btn class="ml-1" @click="clickDelete"  text icon rounded>
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import InfoItem from "../../InfoItem.vue";

export default {
  name: "GynecologyInfoItem",
  props: ["formatDate", "item"],
  components: {
    InfoItem,
  },
  computed: {
    totalChilbirth() {
      const a =
        this.item.abortion !== "" ? parseInt(this.item.abortion, 10) : 0;
      const c =
        this.item.childbirth_c !== ""
          ? parseInt(this.item.childbirth_c, 10)
          : 0;
      const v =
        this.item.childbirth_v !== ""
          ? parseInt(this.item.childbirth_v, 10)
          : 0;
      return a + c + v;
    },
    conditions() {
      let cond = [];

      if (this.menarche) {
        cond.push("Menarquía");
      }

      if (this.pubarche) {
        cond.push("Pubarquia");
      }

      if (this.menopause) {
        cond.push("Menopausia");
      }

      if (this.thelarche) {
        cond.push("Telarquia");
      }

      if (cond.length === 0) {
        return "-";
      }

      const str = cond.forEach((c, index) => {
        if (index === cond.length - 1) {
          return c;
        }

        return c + ", ";
      });
      return str;
    },
  },
  methods: {
    clickEdit() {
      this.$emit("click-edit", this.item);
    },
    clickDelete() {
      this.$emit("click-delete", this.item);
    },
  },
};
</script>
