<template>
  <div>
    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.childbirth_v
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>No. partos vía vaginal: </span>
      <span style="padding-left: 3px">{{ item.childbirth_v }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.childbirth_c
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>No. partos vía cesarea: </span>
      <span style="padding-left: 3px">{{ item.childbirth_c }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.abortion
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>No. de abortos: </span>
      <span style="padding-left: 3px">{{ item.abortion }}</span>
    </div>

    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>No. total de embarazos: </span>
      <span style="padding-left: 3px">{{ totalChilbirth }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues
          ? true
          : this.hideEmptyValues && item.gestationalHypertension
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Hipertensión gestacional: </span>
      <span style="padding-left: 3px">{{ item.gestationalHypertension }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues
          ? true
          : this.hideEmptyValues && item.gestationalDiabetes
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Diabetes gestacional: </span>
      <span style="padding-left: 3px">{{ item.gestationalDiabetes }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues
          ? true
          : this.hideEmptyValues && item.contraceptive
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Método anticonceptivo: </span>
      <span style="padding-left: 3px">{{ item.contraceptive }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues
          ? true
          : this.hideEmptyValues && conditions
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Condiciones: </span>
      <span style="padding-left: 3px">{{ conditions }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 20px"
    >
      <span>Desarrollo: </span>
    </div>
    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      v-html="item.comment || '-'"
    ></div>
  </div>
</template>

<script>
export default {
  name: "GynecologyPrint",
  props: ["item", "off", "hideEmptyValues"],
  computed: {
    totalChilbirth() {
      const a =
        this.item.abortion !== "" ? parseInt(this.item.abortion, 10) : 0;
      const c =
        this.item.childbirth_c !== ""
          ? parseInt(this.item.childbirth_c, 10)
          : 0;
      const v =
        this.item.childbirth_v !== ""
          ? parseInt(this.item.childbirth_v, 10)
          : 0;
      return a + c + v;
    },
    conditions() {
      let cond = [];

      if (this.item.menarche) {
        cond.push("Menarquía");
      }

      if (this.item.pubarche) {
        cond.push("Pubarquia");
      }

      if (this.item.menopause) {
        cond.push("Menopausia");
      }

      if (this.item.thelarche) {
        cond.push("Telarquia");
      }

      if (cond.length === 0) {
        return "";
      }

      const str = cond.forEach((c, index) => {
        if (index === cond.length - 1) {
          return c;
        }

        return c + ", ";
      });
      return str;
    },
  },
};
</script>
