<template>
  <div>
    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.description
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 20px"
    >
      <span>Desarrollo: </span>
    </div>
    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.description
      "
      v-html="item.description || '-'"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PatientGeneralPrint",
  props: ["item", "off", "hideEmptyValues"],
};
</script>
