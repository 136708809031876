<template>
  <div>
    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.description
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Descripción: </span>
      <span style="padding-left: 3px">{{ item.description }}</span>
    </div>
    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.reason
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Motivo: </span>
      <span style="padding-left: 3px">{{ item.reason }}</span>
    </div>
    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 20px"
    >
      <span>Desarrollo: </span>
    </div>
    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      v-html="item.comment || '-'"
    ></div>
  </div>
</template>

<script>
export default {
  name: "LabResultPrint",
  props: ["item", "off", "hideEmptyValues"],
};
</script>
