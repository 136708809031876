<template>
  <div>
    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.habit
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Hábito: </span>
      <span style="padding-left: 3px">{{ item.habit }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.frequency
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Frecuencia: </span>
      <span style="padding-left: 3px">{{ item.frequency }}</span>
    </div>


    
    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 20px"
    >
      <span>Desarrollo: </span>
    </div>
    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      v-html="item.comment || '-'"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ToxicHabitPrint",
  props: ["item", "off", "hideEmptyValues"],
};
</script>
