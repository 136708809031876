<template>
  <div>
    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.reason"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Motivo: </span>
      <span style="padding-left: 3px">{{ item.reason }}</span>
    </div>
    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.weight"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Peso: </span>
      <span style="padding-left: 3px"
        >{{ item.weight }} {{ item.weightMeasure }}</span
      >
    </div>
    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.height"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Talla: </span>
      <span style="padding-left: 3px">
        {{ formatHeightLabel(item) }}
      </span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && imc"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>IMC: </span>
      <span style="padding-left: 3px">{{ imc }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.glucoseTest
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Glucotest: </span>
      <span style="padding-left: 3px">{{ item.glucoseTest }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.FR"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>FR: </span>
      <span style="padding-left: 3px">{{ item.FR }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.FC"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>FC: </span>
      <span style="padding-left: 3px">{{ item.FC }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.temperature
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Temparatura: </span>
      <span style="padding-left: 3px">{{ item.temperature }} °C</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.TA"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>TA: </span>
      <span style="padding-left: 3px">{{ item.TA }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.head"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Cabeza: </span>
      <span style="padding-left: 3px">{{ item.head }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.eyes"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Ojos: </span>
      <span style="padding-left: 3px">{{ item.eyes }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.nose"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Nariz: </span>
      <span style="padding-left: 3px">{{ item.nose }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.ears"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Oidos: </span>
      <span style="padding-left: 3px">{{ item.ears }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.neck"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Cuello: </span>
      <span style="padding-left: 3px">{{ item.neck }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.chest"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Torax: </span>
      <span style="padding-left: 3px">{{ item.chest }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.abdomen"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Abdomen: </span>
      <span style="padding-left: 3px">{{ item.abdomen }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.genitals
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Genitales: </span>
      <span style="padding-left: 3px">{{ item.genitals }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.upperLimbs
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Miembros superiores: </span>
      <span style="padding-left: 3px">{{ item.upperLimbs }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.lowerLimbs
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Miembros inferiores: </span>
      <span style="padding-left: 3px">{{ item.lowerLimbs }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues
          ? true
          : this.hideEmptyValues && item.cardiopulmonary
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Cardiopulmonar: </span>
      <span style="padding-left: 3px">{{ item.cardiopulmonary }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.neurological
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Neurológico: </span>
      <span style="padding-left: 3px">{{ item.neurological }}</span>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 20px"
    >
      <span>Desarrollo: </span>
    </div>
    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      v-html="item.comment || '-'"
    ></div>
  </div>
</template>

<script>
import { convertAndCalculateImc, formatHeightLabel } from "@/helpers/common.js";

export default {
  name: "MedicalEvaluationPrint",
  props: ["item", "off", "hideEmptyValues"],
  computed: {
    imc() {
      return convertAndCalculateImc(this.item);
    },
  },
  methods: {
    formatHeightLabel(item) {
      return formatHeightLabel(item);
    },
  },
};
</script>
