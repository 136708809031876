<template>
  <v-form
    ref="form"
    @submit.prevent="action === 'create' ? createitem() : edititem()"
  >
    <v-row>
      <v-col md="6" cols="12">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Fecha"
              readonly
              outlined
              :value="dateFormatted"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            locale="es-ES"
            color="primary"
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="6" cols="12">
        <v-combobox
          v-model="habit"
          :items="['Café', 'Alcohol', 'Cigarrillo', 'Hookah', 'Vape', 'Tabaco']"
          label="Hábito *"
          outlined
        ></v-combobox>
      </v-col>
      <v-col md="6" cols="12">
        <v-combobox
          v-model="frequency"
          :items="[
            'Todos los días',
            'Semanal',
            'Quincenal',
            'Mensual',
            '2 o 3 días por semana',
            '4 o 5 días por semana',
            'Cada 3 meses',
            'Cada 6 meses',
          ]"
          label="Frecuencia"
          outlined
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <div class="d-flex justify-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                icon
                v-bind="attrs"
                v-on="on"
                @click="showTemplates"
              >
                <v-icon>mdi-clipboard-text-multiple</v-icon>
              </v-btn>
            </template>
            <span class="caption">Seleccionar plantilla</span>
          </v-tooltip>
        </div>
        <VueEditor
          v-model="comment"
          placeholder="Desarrollo"
          :editorToolbar="[
            ['bold', 'italic', 'underline', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
            [{ color: [] }, { background: [] }],
          ]"
        />
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col>
        <v-btn
          small
          depressed
          color="primary"
          class="pa-5"
          type="submit"
          :disabled="!habit"
          :loading="loading"
        >
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapMutations, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import SelectDoctorTemplate from "../../Template/SelectDoctorTemplate.vue";

export default {
  name: "PhysicalActivityForm",
  props: ["action", "patient", "item","field"],
  components: {
    VueEditor,
    SelectDoctorTemplate,
  },
  data() {
    return {
      id: null,
      habit: "",
      frequency: "",
      comment: "",
      loading: false,
      date: moment().startOf("day").toDate().toJSON().slice(0, 10),
      dateMenu: false,
    };
  },
  computed: {
    ...mapGetters(["activities", "iconField"]),
    dateFormatted() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
  },
  methods: {
    ...mapMutations(["setAlert"]),
    showTemplates() {
      this.$emit("show-template-list", this.field);
    },
    setTemplateContent(content) {
      this.comment = content;
    },
    async createitem() {
      this.loading = true;
      try {
        const itemToCreate = {
          habit: this.habit,
          frequency: this.frequency,
          patient: this.patient.id,
          comment: this.comment,
          date: moment(this.date),
        };
        const res = await axios.post("/api/toxicHabit", itemToCreate);
        const { toxicHabit, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.habit = "";
        this.frequency = "";
        this.comment = "";
        this.$emit("created", toxicHabit);
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
    async edititem() {
      this.loading = true;
      try {
        const itemToUpdate = {
          id: this.id,
          habit: this.habit,
          frequency: this.frequency,
          patient: this.patient.id,
          comment: this.comment,
          date: moment(this.date),
        };
        const res = await axios.put(
          `/api/toxicHabit/${this.id}`,
          itemToUpdate
        );
        const { toxicHabit, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$emit("updated", toxicHabit);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(value) {
        if (value) {
          this.id = value.id;
          this.habit = this.habit;
          this.frequency = this.frequency;
          this.date = value.date.slice(0, 10);
          this.comment = value.comment;
        }
      },
    },
  },
};
</script>
