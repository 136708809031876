<template>
  <div>
    <div
      style="padding-top: 15px"
      v-for="(itemPrep, index) in item.prescriptions"
      :key="index"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
    >
      <div>
        <div>
          <div style="font-weight: semibold">
            {{ index + 1 }}-
            <span
              v-if="itemPrep.drug"
              style="padding-right: 5px; font-weight: semibold"
              >{{ itemPrep.drug }}</span
            >
            <span
              v-if="itemPrep.dose"
              style="padding-right: 5px; font-weight: semibold"
              >{{ itemPrep.dose }}</span
            >
            <span
              v-if="itemPrep.presentation"
              style="padding-right: 5px; font-weight: semibold"
              >{{ itemPrep.presentation }}</span
            >
            <span
              v-if="itemPrep.amount"
              style="padding-right: 5px; font-weight: semibold"
              >(#{{ itemPrep.amount }})</span
            >
          </div>
          <div v-if="itemPrep.daysOfUse">Uso: {{ itemPrep.daysOfUse }}</div>
        </div>
      </div>
    </div>

    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 20px"
    >
      <span>{{ item.reference ? "Referencia" : "Observaciones" }}: </span>
    </div>
    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      v-html="item.comment || '-'"
    ></div>
  </div>
</template>

<script>
export default {
  name: "MedicationPrint",
  props: ["item", "off", "hideEmptyValues"],
};
</script>
