<template>
  <div>
    <div v-if="modules && modules.length > 0" class="sticky mb-16">
      <NavegationTabs
        ref="navigationTabs"
        :modules="modules"
        @clickTab="handleClickTab"
      />
    </div>

    <v-dialog
      v-model="showItemsDialog"
      @keydown.esc="showItemsDialog = false"
      persistent
    >
      <v-card class="pa-5">
        <div class="text-right">
          <v-icon @click="showItemsDialog = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <InfoItemsList ref="InfoItemsListRef" :patient="medicalHistory" />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showTemplateList"
      @keydown.esc="showTemplateList = false"
      persistent
    >
      <v-card class="pa-5">
        <div class="text-right">
          <v-icon @click="showTemplateList = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <Title :title="'Seleccionar plantilla'" class="mb-8" />
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="searchTemplateInput"
              label="Buscar"
              append-icon="mdi-magnify"
              type="text"
              clearable
              dense
              outlined
              autofocus
            ></v-text-field>
          </v-col>
        </v-row>
        <TemplateList
          :searchInput="searchTemplateInput"
          :templates="doctorTemplates"
          :hideActions="true"
          :hover="true"
          @item-click="handleTemplateClick"
        />
      </v-card>
    </v-dialog>

    <div class="px-md-3" v-for="(formModule, index) in modules" :key="index">
      <div
        style="margin-bottom: 150px"
        v-for="(tab, indexTab) in formModule.tabs"
        :key="indexTab"
      >
        <span :id="'section-' + tab.value.replace(/\s/g, '')"> </span>
        <div class="d-flex items-center justify-space-between">
          <h3 class="secondary--text">
            <v-icon
              :color="formModule.color || 'secondary'"
              class="rounded-pill mr-2 mb-1"
              >{{ tab.icon }}</v-icon
            >
            {{ getSectionTitle(formModule, tab) }}
          </h3>
          <v-btn
            small
            text
            @click="
              showItems({
                items: getItemsFromMedicalHistory(tab.key),
                title: getSectionTitle(formModule, tab),
                color: formModule.color || 'secondary',
                icon: tab.icon,
                componentName: tab.componentNames.record,
                formComponentName: tab.componentNames.form,
                deleteEndpoint: tab.api.delete,
                key: tab.key,
              })
            "
            :disabled="getItemsFromMedicalHistory(tab.key).length === 0"
          >
            <span style="text-transform: capitalize"
              >{{ countItemsLabel(getItemsFromMedicalHistory(tab.key).length) }}
            </span>
          </v-btn>
        </div>
        <v-divider class="mt-3"></v-divider>
        <div class="my-10">
          <span
            :id="tab.value.replace(/\s/g, '')"
            v-intersect="{
              handler: intersected,
              options: {
                threshold: 0,
              },
            }"
          ></span>
          <component
            :is="tab.componentNames.form"
            :patient="patient"
            :field="tab.key"
            :ref="tab.key"
            :editorTemplate="tab.editorTemplate"
            :code="formModule.code"
            @created="(item) => handleCreated(item, tab.key)"
            @show-template-list="handleShowTemplateList"
            action="create"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import NavegationTabs from "./NavegationTabs.vue";
import InfoItemsList from "./InfoItemsList.vue";
import PhysicalActivityForm from "./Forms/PhysicalActivityForm.vue";
import FamilyBackgroundForm from "./Forms/FamilyBackgroundForm.vue";
import ToxicHabitForm from "./Forms/ToxicHabitForm.vue";
import DiagnosticHistoryForm from "./Forms/DiagnosticHistoryForm.vue";
import AllergyForm from "./Forms/AllergyForm.vue";
import SurgeryForm from "./Forms/SurgeryForm.vue";
import MedicalEvaluationForm from "./Forms/MedicalEvaluationForm.vue";
import DiagnosticForm from "./Forms/DiagnosticForm.vue";
import GynecologyForm from "./Forms/GynecologyForm.vue";
import CardioAnalysisForm from "./Forms/CardioAnalysisForm.vue";
import LabResultForm from "./Forms/LabResultForm.vue";
import MedicationForm from "./Forms/MedicationForm.vue";
import PatientGeneralForm from "./Forms/PatientGeneralForm.vue";
import TemplateList from "../Template/TemplateList.vue";
import Title from "../Title.vue";

export default {
  name: "FormModules",
  props: ["modules", "patient", "getSectionTitle"],
  components: {
    Title,
    TemplateList,
    FamilyBackgroundForm,
    PhysicalActivityForm,
    ToxicHabitForm,
    DiagnosticHistoryForm,
    AllergyForm,
    SurgeryForm,
    MedicalEvaluationForm,
    DiagnosticForm,
    GynecologyForm,
    CardioAnalysisForm,
    LabResultForm,
    MedicationForm,
    NavegationTabs,
    InfoItemsList,
    PatientGeneralForm,
  },
  data() {
    return {
      showItemsDialog: false,
      showTemplateList: false,
      searchTemplateInput: "",
      currentComponentRef: "",
    };
  },
  computed: {
    ...mapGetters(["medicalHistory", "doctorTemplates"]),
  },
  methods: {
    ...mapMutations(["pushToMedicalHistory"]),

    // render list of templates
    handleShowTemplateList(field) {
      this.currentComponentRef = field;
      this.showTemplateList = true;
    },

    // template selected
    handleTemplateClick(template) {
      this.$refs[this.currentComponentRef][0].setTemplateContent(
        template.content
      );
      this.showTemplateList = false;
    },

    showItems(model) {
      this.showItemsDialog = true;
      this.$nextTick(() => {
        this.$refs.InfoItemsListRef.init(model);
      });
    },

    countItemsLabel(count) {
      if (count === 0) {
        return "Sin registros";
      }

      if (count === 1) {
        return "1 registro";
      }

      return count + " registros";
    },

    handleCreated(item, field) {
      this.pushToMedicalHistory({ item, field });
    },

    getItemsFromMedicalHistory(key) {
      return this.medicalHistory[key] || [];
    },

    intersected(entries, observer) {
      const intersecting_element = entries[0];
      if (intersecting_element.isIntersecting && this.$refs.navigationTabs) {
        const tabValue = intersecting_element.target.id;
        this.$refs.navigationTabs.changeCurrentTab(tabValue);
      }
    },

    handleClickTab(tab) {
      // got to section
      setTimeout(() => {
        const options = {
          duration: 200,
          offset: 85,
        };
        this.$vuetify.goTo("#section-" + tab.value.replace(/\s/g, ""), options);
      }, 150);
    },
  },
};
</script>

<style scoped>
.sticky {
  width: 100%;
  position: sticky;
  top: 48px;
  left: 0px;
  z-index: 5;
}
</style>
