<template>
  <v-tabs
    v-model="tab"
    icons-and-text
    height="40"
    color="secondary"
    slider-color="secondary"
  >
    <v-tab
      v-for="(item, index) in tabs"
      :key="index"
      @click="handleClickTab(item)"
      class="mr-2"
    >
      <div class="caption font-weight-medium d-flex align-center">
        <div>
          {{ item.text }}
        </div>
      </div>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "NavegationTabs",
  props: {
    modules: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    tabs() {
      let temp = [];
      for (let formModule of this.modules) {
        temp = temp.concat(formModule.tabs);
      }
      return temp;
    },
  },
  methods: {
    handleClickTab(tab) {
      this.$emit("clickTab", tab);
    },
    changeCurrentTab(value) {
      let index = this.tabs.findIndex(
        (tab) => tab.value.replace(/\s/g, "") === value
      );
      this.tab = index;
    },
  },
};
</script>
