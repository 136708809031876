<template>
  <div>
    <Title :title="'Iniciar consulta'" />
    <div class="mb-8 d-flex">
      <v-tabs
        v-model="startAppointmentTab"
        icons-and-text
        height="40"
        color="secondary"
        slider-color="secondary"
      >
        <v-tab>
          <div class="caption font-weight-medium d-flex align-center">
            <div>Nueva</div>
          </div>
        </v-tab>
        <v-tab>
          <div class="caption font-weight-medium d-flex align-center">
            <div>Seleccionar existente</div>
          </div>
        </v-tab>
      </v-tabs>
    </div>

    <v-container v-show="startAppointmentTab === 0">
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="location"
            :items="locations"
            label="Sede"
            item-value="id"
            item-text="name"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-combobox
            v-model="reason"
            :items="[
              'Consulta',
              'Procedimiento',
              'Entrega de resultado o analítica',
              'Vacunación',
              'Chequeo rutinario',
            ]"
            label="Motivo"
            outlined
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            small
            depressed
            color="primary"
            class="pa-5"
            type="submit"
            :disabled="!reason"
            :loading="loading"
            @click="createAppointment"
          >
            crear e Iniciar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div v-show="startAppointmentTab === 1">
      <v-data-table
        :headers="[
          { text: 'Fecha', value: 'date' },
          { text: 'Sede', value: 'location' },
          { text: 'Motivo', value: 'reason' },
          {
            text: 'Hora estimada',
            value: 'estimatedTime',
            align: 'center',
          },
        ]"
        :items="appointmentsToPick.filter((a) => a.status === 'en espera')"
        :items-per-page="
          appointmentsToPick.filter((a) => a.status === 'en espera').length
        "
        @click:row="rowClick"
        class="row-pointer mt-5"
        item-key="code"
        loading-text="Cargando..."
        no-data-text="Sin datos..."
        item-class="text-center"
        disable-sort
        hide-default-footer
      >
        <template slot="item.date" scope="props">
          {{ formatDate(props.item.date) }}
        </template>
        <template slot="item.reason" scope="props">
          {{ props.item.reason ? props.item.reason : "-" }}
        </template>
        <template slot="item.location" scope="props">
          {{ props.item.location ? props.item.location.name : "-" }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import Title from "../Title.vue";

export default {
  name: "InitAppointmentForm",
  props: ["appointmentsToPick", "formatDate", "patientId"],
  components: {
    Title,
  },
  data() {
    return {
      startAppointmentTab: 0,
      loading: false,
      reason: "",
      location: null,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentLocation", "locations"]),
  },
  methods: {
    ...mapMutations(["setAlert"]),
    rowClick(row) {
      this.$emit("row-click", row);
    },
    async createAppointment() {
      try {
        this.loading = true;

        const payload = {
          doctor: this.currentUser.doctor.id,
          reason: this.reason,
          patient: this.patientId,
          location: this.location ? this.location : this.currentLocation?.id,
        };

        const res = await axios.post("/api/appointment", payload);
        const { appointment, message } = res.data;

        this.$emit("created", appointment);
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });

        this.reason = "";
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.location = this.currentLocation?.id;
  },
};
</script>
