<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <div
          v-if="item.prescriptions.length === 0"
          class="grey--text pt-10 text-center"
        >
          No hay medicamentos agregados...
        </div>

        <div
          v-else
          v-for="(itemPrep, index) in item.prescriptions"
          :key="index"
        >
          <div class="pt-5 px-5 d-flex justify-space-between text-secondary">
            <div>
              <p class="subtitle-2">
                -
                <span v-if="itemPrep.drug" class="mx-1">{{
                  itemPrep.drug
                }}</span>
                <span v-if="itemPrep.dose" class="mx-1">{{
                  itemPrep.dose
                }}</span>
                <span v-if="itemPrep.presentation" class="mx-1">{{
                  itemPrep.presentation
                }}</span>
                <span v-if="itemPrep.amount" class="mx-1"
                  >(#{{ itemPrep.amount }})</span
                >
              </p>
              <p class="subtitle-2 mt-n3" v-if="itemPrep.daysOfUse">
                Uso: {{ itemPrep.daysOfUse }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem
          label="Receta permanente"
          :val="item.permanent ? 'Sí' : 'No'"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Referencia" :val="item.reference ? 'Sí' : 'No'" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="caption pasive--text">Desarrollo</div>
        <div class="body-1" v-if="item.comment" v-html="item.comment"></div>
        <span v-else class="caption font-weight-medium light_secondary--text"
          >-</span
        >
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn @click="clickEdit" text icon rounded>
        <v-icon small>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-btn class="ml-1" @click="clickDelete"  text icon rounded>
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import InfoItem from "../../InfoItem.vue";

export default {
  name: "MedicationInfoItem",
  props: ["formatDate", "item"],
  components: {
    InfoItem,
  },
  methods: {
    clickEdit() {
      this.$emit("click-edit", this.item);
    },
    clickDelete() {
      this.$emit("click-delete", this.item);
    },
  },
};
</script>
