var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      !this.hideEmptyValues ? true : this.hideEmptyValues && _vm.item.childbirth_v
    )?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("No. partos vía vaginal: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.item.childbirth_v))])]):_vm._e(),(
      !this.hideEmptyValues ? true : this.hideEmptyValues && _vm.item.childbirth_c
    )?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("No. partos vía cesarea: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.item.childbirth_c))])]):_vm._e(),(
      !this.hideEmptyValues ? true : this.hideEmptyValues && _vm.item.abortion
    )?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("No. de abortos: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.item.abortion))])]):_vm._e(),_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("No. total de embarazos: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.totalChilbirth))])]),(
      !this.hideEmptyValues
        ? true
        : this.hideEmptyValues && _vm.item.gestationalHypertension
    )?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("Hipertensión gestacional: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.item.gestationalHypertension))])]):_vm._e(),(
      !this.hideEmptyValues
        ? true
        : this.hideEmptyValues && _vm.item.gestationalDiabetes
    )?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("Diabetes gestacional: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.item.gestationalDiabetes))])]):_vm._e(),(
      !this.hideEmptyValues
        ? true
        : this.hideEmptyValues && _vm.item.contraceptive
    )?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("Método anticonceptivo: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.item.contraceptive))])]):_vm._e(),(
      !this.hideEmptyValues
        ? true
        : this.hideEmptyValues && _vm.conditions
    )?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("Condiciones: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.conditions))])]):_vm._e(),(!this.hideEmptyValues ? true : this.hideEmptyValues && _vm.item.comment)?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"20px"}},[_c('span',[_vm._v("Desarrollo: ")])]):_vm._e(),(!this.hideEmptyValues ? true : this.hideEmptyValues && _vm.item.comment)?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,domProps:{"innerHTML":_vm._s(_vm.item.comment || '-')}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }