export const calculateCardioPoints = (item) => {
  let points = 0;
  points += item.ageOver70 ? 5 : 0;
  points += item.heartAttackInThePrevious6Months ? 10 : 0;
  points += item.PVYelevation ? 11 : 0;
  points += item.significantAorticStenosis ? 3 : 0;
  points += item.differentRhythm ? 7 : 0;
  points += item.moreThan5DifferentRhythmPerMinute ? 7 : 0;
  points += item.PO2MoreThan50Mmgh ? 3 : 0;
  points += item.patientBedriddenForNonCardiacCause ? 3 : 0;
  points += item.aortaSurgery ? 4 : 0;
  points += item.emergencySurgery ? 5 : 0;
  return points;
};

export const cardioPointsInterpreter = (value) => {
  if (value <= 5) {
    return {
      text: "Sin riesgo",
      lightModeColor: "dark_primary",
      darkModeColor: "white",
    };
  } else if (value >= 6 && value <= 12) {
    return {
      text: "Bajo riesgo",
      lightModeColor: "orange",
      darkModeColor: "yellow",
    };
  } else if (value >= 13 && value <= 25) {
    return {
      text: "Alto riesgo",
      lightModeColor: "red",
      darkModeColor: "light_error",
    };
  } else if (value >= 26) {
    return {
      text: "Muy alto riesgo",
      lightModeColor: "red",
      darkModeColor: "light_error",
      icon: "mdi-alert",
    };
  } else {
    return { text: "error", lightModeColor: "red", darkModeColor: "red" };
  }
};
