<template>
  <div class="px-md-5">
    <h3 class="secondary--text">
      <v-icon :color="color || 'secondary'" class="rounded-pill mr-2 mb-1">{{
        icon
      }}</v-icon>
      {{ title }}
    </h3>
    <v-divider class="mt-3"></v-divider>
    <v-row v-for="item in items" :key="item.id" align="center" class="my-5">
      <v-col>
        <v-card class="pa-5" style="border-radius: 8px" elevation="6">
          <div>
            <div class="caption text-right pasive--text">
              <span>
                {{ timeAgoFromDate(item.date) }}
              </span>
              <span class="ml-1"> ({{ formatDate(item.date) }}) </span>
            </div>
          </div>
          <v-divider class="my-5"></v-divider>
          <component
            :is="infoItemName"
            :item="item"
            :formatDate="formatDate"
            @click-edit="showFormToEdit"
            @click-delete="showDeleteWarning"
          ></component>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="items && items.length === 0" class="my-10 text-center">
      <div class="pasive--text font-weight-medium">Sin registros</div>
    </div>

    <v-dialog
      v-model="showEditForm"
      @keydown.esc="showEditForm = false"
      persistent
    >
      <v-card class="pa-5" v-if="form && key && patient && item">
        <div class="text-right">
          <v-icon @click="showEditForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <Title :title="'Modificar datos'" class="mb-8" />
        <component
          :is="form"
          :patient="patient"
          :item="item"
          :field="key"
          @updated="(item) => handleUpdated({ item, key })"
          action="update"
        ></component>
      </v-card>
    </v-dialog>

    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deleteItem"
    />
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import moment from "moment";
import axios from "axios";
import TimeAgo from "javascript-time-ago";
import WarningDialog from "../WarningDialog.vue";
import FamilyBackgroundInfoItem from "./InfoItems/FamilyBackgroundInfoItem.vue";
import FamilyBackgroundForm from "./Forms/FamilyBackgroundForm.vue";
import PhysicalActivityInfoItem from "./InfoItems/PhysicalActivityInfoItem.vue";
import PhysicalActivityForm from "./Forms/PhysicalActivityForm.vue";
import AllergyInfoItem from "./InfoItems/AllergyInfoItem.vue";
import AllergyForm from "./Forms/AllergyForm.vue";
import CardioAnalysisInfoItem from "./InfoItems/CardioAnalysisInfoItem.vue";
import CardioAnalysisForm from "./Forms/CardioAnalysisForm.vue";
import DiagnosticHistoryInfoItem from "./InfoItems/DiagnosticHistoryInfoItem.vue";
import DiagnosticHistoryForm from "./Forms/DiagnosticHistoryForm.vue";
import DiagnosticInfoItem from "./InfoItems/DiagnosticInfoItem.vue";
import DiagnosticForm from "./Forms/DiagnosticForm.vue";
import GynecologyInfoItem from "./InfoItems/GynecologyInfoItem.vue";
import GynecologyForm from "./Forms/GynecologyForm.vue";
import LabResultInfoItem from "./InfoItems/LabResultInfoItem.vue";
import LabResultForm from "./Forms/LabResultForm.vue";
import MedicalEvaluationInfoItem from "./InfoItems/MedicalEvaluationInfoItem.vue";
import MedicalEvaluationForm from "./Forms/MedicalEvaluationForm.vue";
import MedicationInfoItem from "./InfoItems/MedicationInfoItem.vue";
import MedicationForm from "./Forms/MedicationForm.vue";
import SurgeryInfoItem from "./InfoItems/SurgeryInfoItem.vue";
import SurgeryForm from "./Forms/SurgeryForm.vue";
import ToxicHabitInfoItem from "./InfoItems/ToxicHabitInfoItem.vue";
import ToxicHabitForm from "./Forms/ToxicHabitForm.vue";
import PatientGeneralInfoItem from "./InfoItems/PatientGeneralInfoItem.vue";
import PatientGeneralForm from "./Forms/PatientGeneralForm.vue";
import Title from "../Title.vue";

export default {
  name: "InfoItemsList",
  components: {
    Title,
    WarningDialog,
    FamilyBackgroundInfoItem,
    FamilyBackgroundForm,
    PhysicalActivityInfoItem,
    PhysicalActivityForm,
    AllergyInfoItem,
    AllergyForm,
    CardioAnalysisInfoItem,
    CardioAnalysisForm,
    DiagnosticHistoryInfoItem,
    DiagnosticHistoryForm,
    DiagnosticInfoItem,
    DiagnosticForm,
    GynecologyInfoItem,
    GynecologyForm,
    LabResultInfoItem,
    LabResultForm,
    MedicalEvaluationForm,
    MedicalEvaluationInfoItem,
    MedicationForm,
    MedicationInfoItem,
    SurgeryInfoItem,
    SurgeryForm,
    ToxicHabitInfoItem,
    ToxicHabitForm,
    PatientGeneralInfoItem,
    PatientGeneralForm,
  },
  props: ["patient"],
  data() {
    return {
      model: 0,
      infoItemName: "",
      items: [],
      title: "",
      icon: "",
      color: "",
      form: "",
      key: "",
      deleteEndpoint: "",
      item: null,
      showEditForm: false,
      deleting: false,
    };
  },
  methods: {
    ...mapMutations([
      "updateFromMedicalHistory",
      "removeFromMedicalHistory",
      "setAlert",
    ]),
    init({
      items,
      componentName,
      title,
      icon,
      color,
      formComponentName,
      key,
      deleteEndpoint,
    }) {
      this.items = items;
      this.infoItemName = componentName;
      this.form = formComponentName;
      this.deleteEndpoint = deleteEndpoint;
      this.title = title;
      this.key = key;
      this.icon = icon;
      this.color = color;
    },
    showFormToEdit(item) {
      this.showEditForm = true;
      this.item = item;
    },
    handleUpdated({ item }) {
      this.updateFromMedicalHistory({ item, field: this.key });
      this.showEditForm = false;
    },
    showDeleteWarning(item) {
      this.item = item;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar el registro?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async deleteItem() {
      try {
        this.deleting = true;
        const res = await axios.delete(
          `/api${this.deleteEndpoint}${this.item.id}`
        );

        // show message response from api
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });

        // remove from state
        this.items = this.items.filter((i) => i.id !== this.item.id);

        // remove from global state
        this.removeFromMedicalHistory({
          item: this.item,
          field: this.key,
        });

        this.$refs.WarningDialogRef.show = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    timeAgoFromDate(date) {
      const itemDate = moment(date, "YYYY-MM-DD");
      const currentDate = moment();

      if (itemDate.format("YYYY-MM-DD") === currentDate.format("YYYY-MM-DD")) {
        return "Hoy";
      }

      const miliseconds = itemDate.valueOf();

      const timeAgo = new TimeAgo("es-ES");
      return timeAgo.format(miliseconds);
    },
  },
};
</script>
