<template>
  <div>
    <v-dialog v-model="showTemplateList">
      <v-card class="pa-5">
        <div class="text-right">
          <v-icon @click="showTemplateList = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <Title :title="'Seleccionar plantilla'" class="mb-8" />
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="searchTemplateInput"
              label="Buscar"
              append-icon="mdi-magnify"
              type="text"
              clearable
              dense
              outlined
              autofocus
            ></v-text-field>
          </v-col>
        </v-row>
        <TemplateList
          :searchInput="searchTemplateInput"
          :templates="doctorTemplates"
          :hideActions="true"
          :hover="true"
          @item-click="handleTemplateClick"
        />
      </v-card>
    </v-dialog>

    <v-form ref="form" @submit.prevent="handleSubmit" v-model="validation">
      <Title :title="title + ' archivo'" class="mb-10" />
      <v-row>
        <v-col cols="12">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Fecha"
                readonly
                outlined
                :value="dateFormatted"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.date"
              locale="es-ES"
              color="primary"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="form.title"
            type="text"
            label="Título *"
            :rules="rules.default"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="title == 'Guardar'">
        <v-col cols="12">
          <v-file-input
            v-model="file"
            prepend-icon=""
            accept="image/*"
            label="Archivo *"
            :rules="rules.require"
            outlined
            show-size
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <div class="d-flex justify-end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="showTemplateList = true"
                >
                  <v-icon>mdi-clipboard-text-multiple</v-icon>
                </v-btn>
              </template>
              <span class="caption">Seleccionar plantilla</span>
            </v-tooltip>
          </div>
          <VueEditor
            v-model="form.comment"
            placeholder="Desarrollo"
            :editorToolbar="[
              ['bold', 'italic', 'underline', 'link'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' },
              ],
              [{ color: [] }, { background: [] }],
            ]"
          />
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col>
          <v-btn
            small
            depressed
            color="primary"
            class="pa-5"
            type="submit"
            :disabled="!validation"
            :loading="saving"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
import Title from "../Title.vue";
import TemplateList from "../Template/TemplateList.vue";
import { mapGetters, mapMutations } from "vuex";
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  name: "PatientFiles",
  props: ["patientId", "title", "data"],
  components: {
    Title,
    TemplateList,
    VueEditor,
  },
  data() {
    return {
      showTemplateList: false,
      searchTemplateInput: "",
      saving: false,
      dateMenu: false,
      file: null,
      url: "",
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 100) || "Debe tener menos de 100 caracteres",
        ],
        require: [(v) => !!v || "Campo obligatorio"],
      },
      validation: true,
      form: {
        id: null,
        title: "",
        comment: "",
        date: moment().startOf("day").toDate().toJSON().slice(0, 10),
      },
    };
  },
  computed: {
    ...mapGetters(["doctorTemplates", "currentUser"]),
    dateFormatted() {
      return this.form.date ? moment(this.form.date).format("DD/MM/YYYY") : "";
    },
  },
  methods: {
    ...mapMutations(["setAlert"]),
    handleTemplateClick(template) {
      this.form.comment = template;
    },
    handleSubmit() {
      if (this.title == "Guardar") {
        this.createFile();
      } else {
        this.updateFile();
      }
    },
    async createFile() {
      try {
        this.saving = true;
        const formData = new FormData();
        formData.append("title", this.form.title);
        formData.append("comment", this.form.comment);
        formData.append("date", this.form.date);
        formData.append("file", this.file);
        formData.append("patientId", this.patientId);
        const res = await axios.post(`/api/patient/file`, formData);
        const { item, message } = res.data;

        if (message) {
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-check-circle",
            timeout: 4000,
            text: message,
          });
        }

        this.$emit("item-created", item);

        this.$refs.form.reset();
        this.form.date = moment().format("YYYY-MM-DD");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message || "Algo salió mal",
        });
      } finally {
        this.saving = false;
      }
    },
    async updateFile() {
      try {
        this.saving = true;
        const res = await axios.put(`/api/patient/file/${this.form.id}`, {
          title: this.form.title,
          comment: this.form.comment,
          date: this.form.date,
          doctorId: this.currentUser?.doctor?.id,
        });
        const { message } = res.data;

        if (message) {
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-check-circle",
            timeout: 4000,
            text: message,
          });
        }

        this.$emit("item-updated");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message || "Algo salió mal",
        });
      } finally {
        this.saving = false;
      }
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(val) {
        this.form.id = val?.id;
        this.form.title = val?.title;
        this.form.comment = val?.comment;
        this.form.date = val?.date || moment().format("YYYY-MM-DD");
        this.url = val?.url;
        this.$refs?.form?.resetValidation();
      },
    },
  },
};
</script>
