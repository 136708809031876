var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();_vm.action === 'create' ? _vm.createitem() : _vm.edititem()}}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha","readonly":"","outlined":"","value":_vm.dateFormatted}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"locale":"es-ES","color":"primary"},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-text-field',{attrs:{"type":"text","label":"Descripción *","outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":_vm.showTemplates}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-text-multiple")])],1)]}}])},[_c('span',{staticClass:"caption"},[_vm._v("Seleccionar plantilla")])])],1),_c('VueEditor',{attrs:{"placeholder":"Desarrollo","editorToolbar":[
          ['bold', 'italic', 'underline', 'link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' },
          ],
          [{ color: [] }, { background: [] }],
        ]},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1),_c('v-row',{staticClass:"mt-8"},[_c('v-col',[_c('v-btn',{staticClass:"pa-5",attrs:{"small":"","depressed":"","color":"primary","type":"submit","disabled":!_vm.description,"loading":_vm.loading}},[_vm._v(" Guardar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }