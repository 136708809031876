<template>
  <div class="text-center grey--text">
    <v-icon color="grey"  >mdi-information-slab-circle-outline</v-icon>
    <div class="mt-2 subtitle-2">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "NoData",
  props: ["text"],
};
</script>
