<template>
  <div>
    <Invoice ref="InvoiceRef" @appointment-updated="handleItemUpdated" />
    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deleteAppointment"
    />
    <v-row>
      <v-col cols="12">
        <div class="caption pasive--text text-right">
          {{ totalItems }}
          {{ totalItems === 1 ? "resultado" : "resultados" }}
        </div>
      </v-col>
    </v-row>
    <v-data-table
      :headers="listHeaders"
      :items="appointments"
      :loading="loadingAppointments"
      :server-items-length="totalItems"
      :footer-props="{
        itemsPerPageText: 'Registros por pág.',
        itemsPerPageOptions: [15, 30, 60],
        pageText: '',
        showCurrentPage: true,
      }"
      disable-sort
      item-key="code"
      loading-text="Cargando..."
      no-data-text="Sin datos..."
      class="mt-5"
      item-class="text-center"
      :no-results-text="'Cargando...'"
      :options.sync="options"
    >
      <template slot="item.patient" scope="props">
        <Patient :patient="props.item.patient">
          {{ props.item.patient.firstName }} {{ props.item.patient.lastName }}
        </Patient>
      </template>
      <template slot="item.time" scope="props">
        <div
          v-if="attending && attending.id === props.item.id"
          class="py-5 primary--text font-weight-medium"
        >
          Consulta en progreso...
        </div>
        <div
          v-else-if="props.item.status == 'anulada'"
          class="py-5 pasive--text"
        >
          Cancelada
          <v-icon class="ml-1" small color="pasive">mdi-close</v-icon>
        </div>
        <div v-else-if="props.item.end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ formatTime(props.item.start) }} -
                {{ formatTime(props.item.end) }}
              </div>
            </template>
            <span class="caption">
              {{ getDiffBetweenTimes(props.item.start, props.item.end) }}
            </span>
          </v-tooltip>
        </div>
        <div
          v-else-if="isBeforeToday(props.item.date)"
          class="py-5 error--text"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                Pendiente
                <v-icon class="ml-1" small color="error"
                  >mdi-clock-outline</v-icon
                >
              </div>
            </template>
            <span class="caption"> Inasistencia </span>
          </v-tooltip>
        </div>
        <div v-else class="py-5 pasive--text">
          Pendiente
          <v-icon class="ml-1" small color="pasive">mdi-clock-outline</v-icon>
        </div>
      </template>
      <template slot="item.paymentAmount" scope="props">
        <span v-if="props.item.totalPaid == props.item.total">
          <div class="pt-4 font-weight-medium">
            RD$ {{ numberWithCommas(props.item.paymentAmount) }}
          </div>
          <div class="pb-4 pt-1 caption pasive--text">
            {{ paymentMethod(props.item.paymentMethod).text }}
            <v-icon class="ml-1" small>
              {{ paymentMethod(props.item.paymentMethod).icon }}
            </v-icon>
          </div>
        </span>
        <span v-else>
          <div class="py-5">-</div>
        </span>
      </template>
      <template slot="item.estimatedTime" scope="props">
        <div>
          {{ props.item.estimatedTime ? props.item.estimatedTime : "-" }}
        </div>
      </template>
      <template slot="item.date" scope="props">
        {{ formatDate(props.item.date) }}
      </template>
      <template slot="item.attendingDate" scope="props">
        {{ formatDate(props.item.attendingDate) }}
      </template>
      <template slot="item.reason" scope="props">
        {{ props.item.reason ? props.item.reason : "-" }}
      </template>
      <template slot="item.location" scope="props">
        {{ props.item.location ? props.item.location.name : "-" }}
      </template>
      <template slot="item.paymentMethod" scope="props">
        {{ paymentMethod(props.item.paymentMethod).text }}
      </template>
      <template slot="item.isPaid" slot-scope="props">
        <InvoiceBanner
          @show-create-form="handleClickPayment(props.item, 'create')"
          @show-info="handleClickPayment(props.item, 'showInfo')"
          :invoice="
            props.item ? props.item.invoices && props.item.invoices[0] : null
          "
          :clickable="true"
        />
      </template>
      <template slot="item.action" scope="props">
        <div class="d-flex justify-center my-3">
          <v-menu bottom offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mr-3" text icon>
                <v-icon size="20px">mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="showClipboard"
                :disabled="props.item.disable_clipboard"
                @click="$emit('click-clipboard-pulse', props.item)"
              >
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"
                      >mdi-clipboard-pulse</v-icon
                    >
                    <div class="subtitle-2 font-weight-regular">
                      Ver historial
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="!(attending && attending.id === props.item.id)"
                @click="handleClickEdit(props.item)"
              >
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"
                      >mdi-square-edit-outline</v-icon
                    >
                    <div class="subtitle-2 font-weight-regular">Modificar</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="!(attending && attending.id === props.item.id)"
                @click="showWarning(props.item)"
              >
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"> mdi-delete </v-icon>
                    <div class="subtitle-2 font-weight-regular">Eliminar</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import Patient from "@/components/Patient/Patient";
import moment from "moment";
import { diffBetweenTimes } from "../../helpers/common";
import Invoice from "../Payment/Invoice.vue";
import WarningDialog from "../WarningDialog.vue";
import InvoiceBanner from "../Payment/InvoiceBanner.vue";

export default {
  name: "AppointmentsList",
  components: {
    Invoice,
    WarningDialog,
    InvoiceBanner,
    Patient,
  },
  props: ["headers", "showClipboard", "totalItems", "appointments"],
  data() {
    return {
      appointment: {},
      payment: null,
      defaultHeaders: [
        { text: "Sede", value: "location" },
        { text: "Paciente", value: "patient" },
        { text: "Descripción", value: "reason" },
        { text: "Hora estimada", value: "estimatedTime" },
        { text: "", value: "action", align: "center" },
      ],
      page: 1,
      pageCount: 0,
      options: {},
      loading: false,
      deleting: false,
    };
  },
  computed: {
    ...mapGetters(["loadingAppointments", "currentUser", "attending"]),
    listHeaders() {
      return this.headers ? this.headers : this.defaultHeaders;
    },
  },
  methods: {
    ...mapMutations(["setWarning", "setAlert", "setAppointments"]),
    isBeforeToday(appointmentDate) {
      const date = moment(appointmentDate, "YYYY-MM-DD");
      const today = moment().format("YYYY-MM-DD");
      return date.isBefore(today);
    },
    getDiffBetweenTimes(time1, time2) {
      return diffBetweenTimes(time1, time2);
    },
    showWarning(item) {
      if (item.status == "anulada") {
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: "La consulta ya se encuentra cancelada...",
        });
        return false;
      }

      Object.assign(this.appointment, item);
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar la consulta de "${this.appointment.patient.firstName} ${this.appointment.patient.lastName}"?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async deleteAppointment() {
      try {
        this.deleting = true;
        const res = await axios.delete(
          `/api/appointment/${this.appointment.id}`
        );
        const { message } = res.data;
        const newAppointmentList = this.appointments.filter(
          (a) => a.id !== this.appointment.id
        );
        this.$emit("appointment-deleted", {
          newAppointmentList,
          appointment: this.appointment,
        });
        this.setAppointments(newAppointmentList);
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$refs.WarningDialogRef.show = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
      }
    },
    numberWithCommas(x) {
      if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatTime(time) {
      if (time) {
        const str = time.substring(0, 5);
        return moment(str, "HH:mm").format("hh:mm A");
      }
    },
    paymentMethod(method) {
      let methodObject;
      switch (method) {
        case "Efectivo":
          methodObject = { text: method, icon: "mdi-cash" };
          break;
        case "Transferencia / Cheque":
          methodObject = { text: method, icon: "mdi-bank" };
          break;
        case "Tarjeta crédito / débito":
          methodObject = { text: method, icon: "mdi-credit-card" };
          break;
        default:
          methodObject = { text: "-", icon: "" };
          break;
      }
      return methodObject;
    },
    handleClickEdit(item) {
      this.$emit("clickEditRow", item);
    },
    handleClickPayment(appointment, action) {
      this.$refs.InvoiceRef.appointment = appointment;
      this.$refs.InvoiceRef.patient = appointment.patient;

      if (action === "create") {
        this.$refs.InvoiceRef.setToCreate();
      }

      if (action === "showInfo") {
        this.$refs.InvoiceRef.invoice = appointment.invoices[0];
        this.$refs.InvoiceRef.showInfo();
      }
    },
    handleItemUpdated(item) {
      this.$emit("item-updated", item);
    },
  },
  watch: {
    options: {
      handler(value) {
        this.$emit("paginate", {
          page: value.page || 1,
          pageSize: value.itemsPerPage || 15,
        });
      },
      deep: true,
    },
  },
};
</script>
