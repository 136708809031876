<template>
  <div>
    <v-dialog v-if="showScala" v-model="showScala" width="600">
      <v-card class="pa-8 d-print-none">
        <p class="text-right">
          <v-icon @click="showScala = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </p>
        <h1 class="headline mb-10 font-weight-bold text-center">Intervalos</h1>
        <p class="my-5 font-weight-medium">0 - 5 = Sin riesgo</p>
        <p class="my-5 font-weight-medium">6 - 12 = Bajo riesgo</p>
        <p class="my-5 font-weight-medium">13 - 25 = Alto riesgo</p>
        <p class="my-5 font-weight-medium">26 o más = Muy alto riesgo</p>
      </v-card>
    </v-dialog>
    <v-form
      ref="form"
      @submit.prevent="action === 'create' ? createitem() : edititem()"
    >
      <v-row>
        <v-col md="6" cols="12">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Fecha"
                readonly
                outlined
                :value="dateFormatted"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              locale="es-ES"
              color="primary"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="ageOver70"
            label="Edad superior a los 70 años (5)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="heartAttackInThePrevious6Months"
            label="Infarto a los 6 meses previos (10)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="PVYelevation"
            label="Galope diastólico PVY elevada (11)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="significantAorticStenosis"
            label="Estenosis aórtica significativa (3)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="differentRhythm"
            label="Ritmo distinto al sinusal o extrasístoles supraventriculares (7)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="moreThan5DifferentRhythmPerMinute"
            label="Más de cinco extrasístoles supraventriculares por minuto (7)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="PO2MoreThan50Mmgh"
            label="PO2 < 60 o POCO2 > 50 Mmhg (3)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="patientBedriddenForNonCardiacCause"
            label="K < 3.0 o CO3H > 20 UREA > 50 CREAT > 3.0 AST elevada o Hepatopatía Crónica Paciente encamado por causa no cardíaca (3)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="aortaSurgery"
            label="Cirugía Intraperitoneal, Intratoracica, Aorta (4)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="emergencySurgery"
            label="Cirugía de urgencia (5)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <p @click="showScala = true" style="cursor: pointer">
        Total puntos acumulados:
        <span
          :class="`${
            cardioAnalysisInterpreter(totalPoints).lightModeColor
          }--text font-weight-medium`"
        >
          {{ totalPoints }} | {{ cardioAnalysisInterpreter(totalPoints).text }}
          <v-icon
            v-if="totalPoints >= 26"
            :color="cardioAnalysisInterpreter(totalPoints).lightModeColor"
            class="ml-1 mb-1"
          >
            {{ cardioAnalysisInterpreter(totalPoints).icon }}
          </v-icon>
        </span>
      </p>
      <v-row>
        <v-col cols="12" md="12">
          <div class="d-flex justify-end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="showTemplates"
                >
                  <v-icon>mdi-clipboard-text-multiple</v-icon>
                </v-btn>
              </template>
              <span class="caption">Seleccionar plantilla</span>
            </v-tooltip>
          </div>
          <VueEditor
            v-model="comment"
            placeholder="Desarrollo"
            :editorToolbar="[
              ['bold', 'italic', 'underline', 'link'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' },
              ],
              [{ color: [] }, { background: [] }],
            ]"
          />
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col>
          <v-btn
            small
            depressed
            color="primary"
            class="pa-5"
            type="submit"
            :loading="loading"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapMutations, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import SelectDoctorTemplate from "../../Template/SelectDoctorTemplate.vue";

export default {
  name: "CardioAnalysisForm",
  props: ["action", "patient", "item", "field"],
  components: {
    VueEditor,
    SelectDoctorTemplate,
  },
  data() {
    return {
      ageOver70: false,
      heartAttackInThePrevious6Months: false,
      PVYelevation: false,
      significantAorticStenosis: false,
      differentRhythm: false,
      moreThan5DifferentRhythmPerMinute: false,
      PO2MoreThan50Mmgh: false,
      patientBedriddenForNonCardiacCause: false,
      aortaSurgery: false,
      emergencySurgery: false,
      comment: "",
      showScala: false,
      loading: false,
      date: moment().startOf("day").toDate().toJSON().slice(0, 10),
      dateMenu: false,
    };
  },
  computed: {
    ...mapGetters(["pathologies"]),
    dateFormatted() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
    totalPoints() {
      let points = 0;
      points += this.ageOver70 ? 5 : 0;
      points += this.heartAttackInThePrevious6Months ? 10 : 0;
      points += this.PVYelevation ? 11 : 0;
      points += this.significantAorticStenosis ? 3 : 0;
      points += this.differentRhythm ? 7 : 0;
      points += this.moreThan5DifferentRhythmPerMinute ? 7 : 0;
      points += this.PO2MoreThan50Mmgh ? 3 : 0;
      points += this.patientBedriddenForNonCardiacCause ? 3 : 0;
      points += this.aortaSurgery ? 4 : 0;
      points += this.emergencySurgery ? 5 : 0;
      return points;
    },
  },
  methods: {
    ...mapMutations(["setAlert"]),
    showTemplates() {
      this.$emit("show-template-list", this.field);
    },
    setTemplateContent(content) {
      this.comment = content;
    },
    cardioAnalysisInterpreter(value) {
      if (value <= 5) {
        return {
          text: "Sin riesgo",
          lightModeColor: "dark_primary",
          darkModeColor: "white",
        };
      } else if (value >= 6 && value <= 12) {
        return {
          text: "Bajo riesgo",
          lightModeColor: "orange",
          darkModeColor: "yellow",
        };
      } else if (value >= 13 && value <= 25) {
        return {
          text: "Alto riesgo",
          lightModeColor: "red",
          darkModeColor: "light_error",
        };
      } else if (value >= 26) {
        return {
          text: "Muy alto riesgo",
          lightModeColor: "red",
          darkModeColor: "light_error",
          icon: "mdi-alert",
        };
      } else {
        return { text: "error", lightModeColor: "red", darkModeColor: "red" };
      }
    },
    async createitem() {
      this.loading = true;
      try {
        const itemToCreate = {
          ageOver70: this.ageOver70,
          heartAttackInThePrevious6Months: this.heartAttackInThePrevious6Months,
          PVYelevation: this.PVYelevation,
          significantAorticStenosis: this.significantAorticStenosis,
          differentRhythm: this.differentRhythm,
          moreThan5DifferentRhythmPerMinute:
            this.moreThan5DifferentRhythmPerMinute,
          PO2MoreThan50Mmgh: this.PO2MoreThan50Mmgh,
          patientBedriddenForNonCardiacCause:
            this.patientBedriddenForNonCardiacCause,
          aortaSurgery: this.aortaSurgery,
          emergencySurgery: this.emergencySurgery,
          patient: this.patient.id,
          comment: this.comment,
          date: moment(this.date),
        };
        const res = await axios.post("/api/cardioanalysis", itemToCreate);
        const { cardioAnalysis, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.pathology = "";
        this.comment = "";
        this.history = false;
        this.$emit("created", cardioAnalysis);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
    async edititem() {
      this.loading = true;
      try {
        const itemToUpdate = {
          id: this.id,
          ageOver70: this.ageOver70,
          heartAttackInThePrevious6Months: this.heartAttackInThePrevious6Months,
          PVYelevation: this.PVYelevation,
          significantAorticStenosis: this.significantAorticStenosis,
          differentRhythm: this.differentRhythm,
          moreThan5DifferentRhythmPerMinute:
            this.moreThan5DifferentRhythmPerMinute,
          PO2MoreThan50Mmgh: this.PO2MoreThan50Mmgh,
          patientBedriddenForNonCardiacCause:
            this.patientBedriddenForNonCardiacCause,
          aortaSurgery: this.aortaSurgery,
          emergencySurgery: this.emergencySurgery,
          comment: this.comment,
          date: moment(this.date),
        };
        const res = await axios.put(
          `/api/cardioanalysis/${this.id}`,
          itemToUpdate
        );
        const { cardioAnalysis, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$emit("updated", cardioAnalysis);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(value) {
        if (value) {
          this.id = value.id;
          this.ageOver70 = value.ageOver70 || false;
          this.heartAttackInThePrevious6Months =
            value.heartAttackInThePrevious6Months || false;
          this.PVYelevation = value.PVYelevation || false;
          this.significantAorticStenosis =
            value.significantAorticStenosis || false;
          this.differentRhythm = value.differentRhythm || false;
          this.moreThan5DifferentRhythmPerMinute =
            value.moreThan5DifferentRhythmPerMinute || false;
          this.PO2MoreThan50Mmgh = value.PO2MoreThan50Mmgh || false;
          this.patientBedriddenForNonCardiacCause =
            value.patientBedriddenForNonCardiacCause || false;
          this.aortaSurgery = value.aortaSurgery || false;
          this.emergencySurgery = value.emergencySurgery || false;
          this.date = value.date.slice(0, 10);
          this.comment = value.comment;
        }
      },
    },
  },
};
</script>
