<template>
  <div>
    <component
      :is="form"
      :ref="field"
      :patient="patient"
      :item="item"
      :field="field"
      :action="action"
      @updated="handleUpdated"
      @created="handleCreated"
      @show-template-list="handleShowTemplateList"
    ></component>
  </div>
</template>

<script>
import FamilyBackgroundForm from "./Forms/FamilyBackgroundForm.vue";
import PhysicalActivityForm from "./Forms/PhysicalActivityForm.vue";
import AllergyForm from "./Forms/AllergyForm.vue";
import CardioAnalysisForm from "./Forms/CardioAnalysisForm.vue";
import DiagnosticHistoryForm from "./Forms/DiagnosticHistoryForm.vue";
import DiagnosticForm from "./Forms/DiagnosticForm.vue";
import GynecologyForm from "./Forms/GynecologyForm.vue";
import LabResultForm from "./Forms/LabResultForm.vue";
import MedicalEvaluationForm from "./Forms/MedicalEvaluationForm.vue";
import MedicationForm from "./Forms/MedicationForm.vue";
import SurgeryForm from "./Forms/SurgeryForm.vue";
import ToxicHabitForm from "./Forms/ToxicHabitForm.vue";
import PatientGeneralForm from "./Forms/PatientGeneralForm.vue";

export default {
  name: "FormModule",
  props: ["form", "patient", "item", "field", "action"],
  components: {
    FamilyBackgroundForm,
    PhysicalActivityForm,
    AllergyForm,
    CardioAnalysisForm,
    DiagnosticHistoryForm,
    DiagnosticForm,
    GynecologyForm,
    LabResultForm,
    MedicalEvaluationForm,
    MedicationForm,
    SurgeryForm,
    ToxicHabitForm,
    PatientGeneralForm,
  },
  methods: {
    handleUpdated(params) {
      this.$emit("updated", params);
    },
    handleCreated(params) {
      this.$emit("created", params);
    },
    handleShowTemplateList(params) {
      this.$emit("show-template-list", params);
    },
    setTemplateContent(content) {
      this.$refs[this.field][0].setTemplateContent(content);
    },
  },
};
</script>
