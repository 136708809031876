var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      !this.hideEmptyValues ? true : this.hideEmptyValues && _vm.item.activity
    )?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("Actividad: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.item.activity))])]):_vm._e(),(
      !this.hideEmptyValues ? true : this.hideEmptyValues && _vm.item.dedicatedTime
    )?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("Tiempo decicado: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.item.dedicatedTime))])]):_vm._e(),(
      !this.hideEmptyValues ? true : this.hideEmptyValues && _vm.item.frequency
    )?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"3px"}},[_c('span',[_vm._v("Fecuencia: ")]),_c('span',{staticStyle:{"padding-left":"3px"}},[_vm._v(_vm._s(_vm.item.frequency))])]):_vm._e(),(!this.hideEmptyValues ? true : this.hideEmptyValues && _vm.item.comment)?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,staticStyle:{"padding-top":"20px"}},[_c('span',[_vm._v("Desarrollo: ")])]):_vm._e(),(!this.hideEmptyValues ? true : this.hideEmptyValues && _vm.item.comment)?_c('div',{class:`${_vm.off ? 'text-decoration-line-through pasive--text' : ''}`,domProps:{"innerHTML":_vm._s(_vm.item.comment || '-')}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }