<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="dark_primary"
          icon
          v-bind="attrs"
          v-on="on"
          @click="showDialog = true"
        >
          <v-icon>mdi-clipboard</v-icon>
        </v-btn>
      </template>
      <span class="caption">Seleccionar plantilla</span>
    </v-tooltip>

    <v-dialog v-model="showDialog" @keydown.esc="showDialog = false" persistent>
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showDialog = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <h1 class="text-center headline lighten-2 mb-8">
          Seleccionar plantilla
        </h1>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="searchInput"
              label="Buscar"
              append-icon="mdi-magnify"
              type="text"
              clearable
              dense
              outlined
              autofocus
            ></v-text-field>
          </v-col>
        </v-row>
        <TemplateList
          :searchInput="searchInput || ''"
          :templates="doctorTemplates"
          :hideActions="true"
          :hover="true"
          @item-click="handleItemClick"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TemplateList from "./TemplateList.vue";

export default {
  name: "SelectDoctorTemplate",
  components: {
    TemplateList,
  },
  data() {
    return {
      selected: null,
      searchInput: "",
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters(["doctorTemplates"]),
  },
  methods: {
    handleItemClick(item) {
      this.selected = item;
    },
  },
  watch: {
    selected: function (value) {
      this.showDialog = false;
      this.$emit("change", value);
    },
  },
};
</script>
