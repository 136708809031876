<template>
  <div>
    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deletePatientFile"
    />
    <div class="d-flex align-center mb-6">
      <v-btn
        title="Guardar un nuevo registro"
        color="primary"
        class="mr-3"
        small
        depressed
        @click="handleClickNew"
      >
        nuevo
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            icon
            title="Refrescar datos"
            @click="getFiles"
            :disabled="fetching"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span class="caption">Refrescar datos</span>
      </v-tooltip>
    </div>

    <v-row v-if="fetching" class="pt-3">
      <v-col cols="12">
        <div
          class="d-flex text-center justify-center mt-10"
          style="width: 100%"
        >
          <div>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else-if="items.length == 0">
      <v-col cols="12">
        <NoData text="Sin registros..." />
      </v-col>
    </v-row>

    <div class="mt-10">
      <v-row v-for="item in items" :key="item.id" class="my-2 px-2 px-md-5">
        <v-col cols="12">
          <v-card class="pa-5" style="border-radius: 8px" elevation="6">
            <div class="d-md-flex justify-space-between align-center">
              <h4 class="secondary--text">{{ item.title }}</h4>
              <div class="caption secondary--text mt-md-0 mt-3">
                <span>
                  {{ timeAgoFromDate(item.date) }}
                </span>
                <span class="ml-1"> ({{ formatDate(item.date) }}) </span>
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            <v-row>
              <v-col>
                <v-img
                  @click="handleClickFile(item)"
                  :aspect-ratio="16 / 9"
                  :width="500"
                  class="pointer round"
                  :src="item.url"
                ></v-img>
              </v-col>
            </v-row>
            <v-row v-if="item.comment" class="mt-3">
              <v-col cols="12">
                <div class="caption pasive--text">Desarrollo</div>
                <div class="body-1" v-html="item.comment"></div>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-btn @click="clickEdit(item)" text icon rounded>
                <v-icon small>mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn class="ml-1" @click="clickDelete(item)" text icon rounded>
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="showFullScreen"
      @keydown.esc="showFullScreen = false"
      width="100%"
      heigth="100%"
    >
      <v-card v-if="currentItem">
        <div class="d-flex align-center justify-space-between px-5 py-3">
          <h4 class="secondary--text">{{ currentItem.title }}</h4>
          <v-btn icon @click="showFullScreen = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
        <v-img :width="'100%'" :heigth="'100%'" :src="currentItem.url"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="form.show"
      @keydown.esc="form.show = false"
      persistent
      width="850"
    >
      <v-card class="pa-5">
        <div class="text-right">
          <v-btn icon @click="form.show = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
        <PatientFileForm
          :title="form.title"
          :data="form.data"
          :patientId="patientId"
          @item-created="handleFormSubmited"
          @item-updated="handleFormSubmited"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TimeAgo from "javascript-time-ago";
import moment from "moment";
import { mapGetters, mapMutations, mapActions } from "vuex";
import PatientFileForm from "./PatientFileForm.vue";
import NoData from "../NoData.vue";
import WarningDialog from "../WarningDialog.vue";
import axios from "axios";

export default {
  name: "PatientFiles",
  props: ["patientId"],
  components: {
    PatientFileForm,
    NoData,
    WarningDialog,
  },
  data() {
    return {
      items: [],
      fetching: false,
      form: {
        show: false,
        title: "Guardar",
        data: null,
      },
      currentItem: {
        title: "",
        comment: "",
        date: "",
      },
      showFullScreen: false,
      deleting: false,
    };
  },
  computed: {
    ...mapGetters(["doctorTemplates", "currentUser"]),
  },
  methods: {
    ...mapMutations(["setAlert"]),
    ...mapActions(["fetchPatientFiles"]),
    timeAgoFromDate(date) {
      const itemDate = moment(date, "YYYY-MM-DD");
      const currentDate = moment();
      if (itemDate.format("YYYY-MM-DD") === currentDate.format("YYYY-MM-DD")) {
        return "Hoy";
      }
      const miliseconds = itemDate.valueOf();
      const timeAgo = new TimeAgo("es-ES");
      return timeAgo.format(miliseconds);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    handleClickNew() {
      this.form.title = "Guardar";
      this.form.show = true;
      this.form.data = {
        title: "",
        comment: "",
        date: "",
      };
    },
    clickEdit(item) {
      this.form.title = "Modificar";
      this.form.show = true;
      this.form.data = item;
    },

    handleClickFile(item) {
      this.showFullScreen = true;
      this.currentItem = item;
    },
    handleFormSubmited() {
      this.getFiles();
      this.form.show = false;
    },
    clickDelete(item) {
      this.currentItem = item;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar el archivo ${this.currentItem.title}?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async deletePatientFile() {
      try {
        this.deleting = true;
        const res = await axios.delete(
          `/api/patient/file/${this.currentItem.id}`,
          {
            data: {
              doctorId: this.currentUser?.doctor?.id,
            },
          }
        );
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.items = this.items.filter((i) => i.id !== this.currentItem.id);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message || "Error innesperado...",
        });
      } finally {
        this.deleting = false;
        this.$refs.WarningDialogRef.show = false;
      }
    },
    async getFiles() {
      this.fetching = true;
      const data = await this.fetchPatientFiles(this.patientId);
      this.fetching = false;
      this.items = data?.items;
    },
  },
  mounted() {
    if (this.currentUser.role === "doctor") {
      this.getFiles();
    }
  },
};
</script>
