<template>
  <v-form
    ref="form"
    @submit.prevent="action === 'create' ? createitem() : edititem()"
  >
    <v-row>
      <v-col md="6" cols="12">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Fecha"
              readonly
              outlined
              :value="dateFormatted"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            locale="es-ES"
            color="primary"
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="childbirth_v"
          type="number"
          @wheel="$event.target.blur()"
          label="No. partos vía vaginal"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="childbirth_c"
          type="number"
          @wheel="$event.target.blur()"
          label="No. partos vía cesarea"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="abortion"
          type="number"
          @wheel="$event.target.blur()"
          label="No. de abortos"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="totalChilbirth"
          type="number"
          @wheel="$event.target.blur()"
          label="No. total de embarazos"
          readonly
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="gestationalHypertension"
          type="number"
          @wheel="$event.target.blur()"
          label="Hipertensión gestacional"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="gestationalDiabetes"
          type="number"
          @wheel="$event.target.blur()"
          label="Diabetes gestacional"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="contraceptive"
          type="text"
          label="Método anticonceptivo"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-combobox
          v-model="chips"
          :items="conditions"
          chips
          label="Condiciones"
          multiple
          outlined
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              color="pink"
              class="white--text"
              close
              small
              @click="select"
              @click:close="remove(item)"
            >
              <strong>{{ item }}</strong>
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <div class="d-flex justify-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                icon
                v-bind="attrs"
                v-on="on"
                @click="showTemplates"
              >
                <v-icon>mdi-clipboard-text-multiple</v-icon>
              </v-btn>
            </template>
            <span class="caption">Seleccionar plantilla</span>
          </v-tooltip>
        </div>
        <VueEditor
          v-model="comment"
          placeholder="Desarrollo"
          :editorToolbar="[
            ['bold', 'italic', 'underline', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
            [{ color: [] }, { background: [] }],
          ]"
        />
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col>
        <v-btn
          small
          depressed
          color="primary"
          class="pa-5"
          type="submit"
          :disabled="!validation"
          :loading="loading"
        >
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapMutations, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import SelectDoctorTemplate from "../../Template/SelectDoctorTemplate.vue";

export default {
  name: "DiagnosticForm",
  props: ["action", "patient", "item", "field"],
  components: {
    VueEditor,
    SelectDoctorTemplate,
  },
  data() {
    return {
      id: null,
      abortion: "",
      childbirth_c: "",
      childbirth_v: "",
      gestationalHypertension: "",
      gestationalDiabetes: "",
      contraceptive: "",
      comment: "",
      menarche: false,
      pubarche: false,
      menopause: false,
      thelarche: false,
      loading: false,
      chips: [],
      conditions: ["Menarquía", "Pubarquia", "Menopausia", "Telarquia"],
      loading: false,
      date: moment().startOf("day").toDate().toJSON().slice(0, 10),
      dateMenu: false,
    };
  },
  computed: {
    ...mapGetters(["pathologies"]),
    dateFormatted() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
    validation() {
      return (
        this.comment !== "" ||
        this.abortion !== "" ||
        this.childbirth_c !== "" ||
        this.childbirth_v !== "" ||
        this.gestationalHypertension !== "" ||
        this.gestationalDiabetes !== "" ||
        this.contraceptive !== ""
      );
    },
    totalChilbirth() {
      const a = this.abortion !== "" ? parseInt(this.abortion, 10) : 0;
      const c = this.childbirth_c !== "" ? parseInt(this.childbirth_c, 10) : 0;
      const v = this.childbirth_v !== "" ? parseInt(this.childbirth_v, 10) : 0;
      return a + c + v;
    },
  },
  methods: {
    ...mapMutations(["setAlert"]),
    showTemplates() {
      this.$emit("show-template-list", this.field);
    },
    setTemplateContent(content) {
      this.comment = content;
    },
    async createitem() {
      this.loading = true;
      try {
        this.checkChips();
        const itemToCreate = {
          abortion: this.abortion || 0,
          childbirth_c: this.childbirth_c || 0,
          childbirth_v: this.childbirth_v || 0,
          gestationalHypertension: this.gestationalHypertension || 0,
          gestationalDiabetes: this.gestationalDiabetes || 0,
          contraceptive: this.contraceptive,
          patient: this.patient.id,
          comment: this.comment,
          menarche: this.menarche,
          pubarche: this.pubarche,
          menopause: this.menopause,
          thelarche: this.thelarche,
          date: moment(this.date),
        };
        const res = await axios.post("/api/gynecology", itemToCreate);
        const { gynecology, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.contraceptive = "";
        this.comment = "";
        this.$emit("created", gynecology);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
    async edititem() {
      this.loading = true;
      try {
        const itemToUpdate = {
          id: this.id,
          abortion: this.abortion || 0,
          childbirth_c: this.childbirth_c || 0,
          childbirth_v: this.childbirth_v || 0,
          gestationalHypertension: this.gestationalHypertension || 0,
          gestationalDiabetes: this.gestationalDiabetes || 0,
          contraceptive: this.contraceptive,
          patient: this.patient.id,
          comment: this.comment,
          menarche: this.menarche,
          pubarche: this.pubarche,
          menopause: this.menopause,
          thelarche: this.thelarche,
          date: moment(this.date),
        };
        const res = await axios.put(`/api/gynecology/${this.id}`, itemToUpdate);
        const { gynecology, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$emit("updated", gynecology);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },
    checkChips() {
      this.menarche = this.chips.includes("Menarquía");
      this.pubarche = this.chips.includes("Pubarquia");
      this.menopause = this.chips.includes("Menopausia");
      this.thelarche = this.chips.includes("Telarquia");
    },
    addToChip() {
      if (this.menarche) this.chips.push("Menarquía");
      if (this.pubarche) this.chips.push("Pubarquia");
      if (this.menopause) this.chips.push("Menopausia");
      if (this.thelarche) this.chips.push("Telarquia");
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(value) {
        if (value) {
          this.id = value.id;
          this.abortion = value.abortion;
          this.childbirth_c = value.childbirth_c;
          this.childbirth_v = value.childbirth_v;
          this.gestationalHypertension = value.gestationalHypertension;
          this.gestationalDiabetes = value.gestationalDiabetes;
          this.contraceptive = value.contraceptive;
          this.time = value.time;
          this.date = value.date.slice(0, 10);
          this.comment = value.comment;
          this.menarche = value.menarche;
          this.pubarche = value.pubarche;
          this.menopause = value.menopause;
          this.thelarche = value.thelarche;
          this.addToChip();
        }
      },
    },
  },
};
</script>
