<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <InfoItem
          :icon="'mdi-card-account-details'"
          label="Identificación"
          :val="`${patient.identificationDocument} (${patient.identificationType})`"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem
          :icon="'mdi-calendar'"
          label="Fecha de nacimiento"
          :val="formatDate(patient)"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem
          :icon="'mdi-account-tie'"
          label="Profesión/oficio"
          :val="patient.profession"
        />
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col cols="12" md="4">
        <InfoItem
          icon="mdi-home-heart"
          label="Aseguradora"
          :val="ARSdescription"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem
          icon="mdi-card-text"
          label="No. afiliado"
          :val="patient.ARSNumber"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem icon="mdi-clipboard-pulse" label="NSS" :val="patient.NSS" />
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col cols="12" md="4">
        <InfoItem icon="mdi-phone" label="Teléfono" :val="patient.phone" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem
          icon="mdi-phone-alert"
          label="Teléfono para emergencias"
          :val="patient.emergencyNumber"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem
          icon="mdi-email"
          label="Correo electrónico"
          :val="patient.email"
        />
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col cols="12" md="4">
        <InfoItem
          icon="mdi-map-marker"
          label="Provincia"
          :val="patient.province"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem icon="mdi-home" label="Dirección" :val="patient.address" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import InfoItem from "../InfoItem.vue";

export default {
  name: "PatientInfo",
  components: {
    InfoItem,
  },
  props: ["patient", "hideDividers"],
  methods: {
    formatDate(patient) {
      return patient.bornDate
        ? moment(patient.bornDate).format("DD/MM/YYYY")
        : "-";
    },
  },
  computed: {
    genderColor() {
      if (this.patient.gender === "F") {
        return "#F06292";
      } else if (this.patient.gender === "M") {
        return "info";
      } else {
        return "blue-grey";
      }
    },
    generalDataComplete() {
      return (
        this.patient.firstName &&
        this.patient.lastName &&
        this.patient.gender &&
        this.patient.identificationDocument &&
        this.patient.profession &&
        this.patient.bloodType &&
        this.patient.bornDate
      );
    },
    contactDataComplete() {
      return (
        this.patient.phone && this.patient.email && this.patient.emergencyNumber
      );
    },
    locationDataComplete() {
      return this.patient.province && this.patient.address;
    },
    healthSecurityDataComplete() {
      return this.patient.ARS && this.patient.NSS;
    },
    ARSdescription() {
      return this.patient?.ARS?.description;
    },
  },
};
</script>
