<template>
  <div>
    <v-row class="d-flex">
      <v-col
        cols="12"
        class="d-md-flex justify-space-between align-center items-center"
      >
        <div class="d-md-flex align-center">
          <v-btn
            depressed
            small
            title="Agregar un paciente a la agenda"
            color="primary"
            @click="handleClickAdd"
          >
            agendar
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <AppointmentsList
          :headers="headers"
          :totalItems="count"
          :appointments="appointmentList"
          :showClipboard="true"
          @appointment-deleted="handleAppointmentDeleted"
          @item-updated="handleAppointmentUpdated"
          @clickEditRow="handleClickEdit"
          @click-clipboard-pulse="handleClickClipboardPulse"
          @paginate="getData"
          ref="appointmentsList"
        />
      </v-col>
    </v-row>

    <AppointmentForm
      ref="appointmentForm"
      :patientFixed="true"
      :pickDate="true"
      @appointment-updated="handleAppointmentUpdated"
      @created="handleAppointmentCreated"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import AppointmentForm from "../Appointment/AppointmentForm.vue";
import AppointmentsList from "../Appointment/AppointmentsList.vue";
import { mapMutations } from "vuex/dist/vuex.common.js";

export default {
  name: "PatientAppointments",
  components: {
    AppointmentsList,
    AppointmentForm,
  },
  props: ["patientId", "patient", "medicalRegisterDates"],
  data() {
    return {
      appointment: {
        date: "2024-23-01",
      },
      patientAppointments: [],
      count: 0,
      term: "",
      page: "",
      pageSize: "",
      headers: [
        { text: "Fecha", value: "date" },
        { text: "Sede", value: "location" },
        { text: "Motivo", value: "reason" },
        { text: "Hora estimada", value: "estimatedTime" },
        { text: "Atención", value: "time" },
        { text: "Estado de pago", value: "isPaid", align: "center" },
        { text: "", value: "action", align: "center" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "attending",
      "currentLocation",
      "appointments",
    ]),
    appointmentList() {
      return this.patientAppointments.map((item) => {
        const disable_clipboard = !this.medicalRegisterDates?.includes(
          moment(item.date).format("DD/MM/YYYY")
        );

        return {
          ...item,
          disable_clipboard,
        };
      });
    },
  },
  methods: {
    ...mapActions(["fetchAppointmentsByDoctor"]),
    ...mapMutations([
      "unshiftAppointment",
      "updateAppointment",
      "setAppointments",
    ]),
    async getData(params) {
      this.page = params?.page || 1;
      this.pageSize = params?.pageSize || 15;

      const { count, items } = await this.fetchAppointmentsByDoctor({
        doctorId: this.currentUser.doctor.id,
        patientId: this.patientId,
        term: this.term,
        page: this.page,
        pageSize: this.pageSize,
        orderBy: [
          {
            column: "appointment.date",
            direction: "DESC",
          },
          {
            column: "appointment.updatedAt",
            direction: "DESC",
          },
        ],
      });

      // this.patientAppointments = items;
      this.setAppointments(items);
      this.count = count;

      window.scrollTo(0, 0);
    },
    handleAppointmentDeleted({ newAppointmentList }) {
      this.setAppointments(newAppointmentList || []);
    },
    handleAppointmentUpdated(item) {
      this.updateAppointment(item);
    },
    handleClickAdd() {
      this.$refs.appointmentForm.action = "create";
      this.$refs.appointmentForm.time = null;
      this.$refs.appointmentForm.form = {
        patient: this.patient,
        reason: "",
        date: moment().format("YYYY-MM-DD"),
        estimatedTime: "",
        location: this.currentLocation?.id,
      };
      this.$refs.appointmentForm.appointmentDialog = true;
    },
    handleClickEdit(item) {
      const appointmentByEstimatedTime =
        item.estimatedTime && item.estimatedTime !== "Sin definir";
      this.$refs.appointmentForm.time = appointmentByEstimatedTime
        ? moment(item.estimatedTime, "hh:mm A").format("HH:mm")
        : null;

      this.$refs.appointmentForm.action = "update";
      this.$refs.appointmentForm.form = { ...item };
      this.$refs.appointmentForm.currentDate = item.date;
      this.$refs.appointmentForm.appointmentDialog = true;
    },
    getItems() {
      return this.patientAppointments;
    },
    handleAppointmentCreated(appointment) {
      this.unshiftAppointment({
        ...appointment,
        patient: this.patient,
      });
    },
    handleClickPayment(apointment) {
      this.$refs.appointmentsList.handleClickPayment(apointment, "create");
    },
    handleClickClipboardPulse(apointment) {
      const date = moment(apointment.date).format("DD/MM/YYYY");
      this.$emit("show-medical-items-by-date", date);
    },
  },
  watch: {
    appointments: {
      handler(value) {
        this.patientAppointments = value || [];
      },
    },
  },
};
</script>
