<template>
  <div>
    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.activity
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Actividad: </span>
      <span style="padding-left: 3px">{{ item.activity }}</span>
    </div>

     <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.dedicatedTime
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Tiempo decicado: </span>
      <span style="padding-left: 3px">{{ item.dedicatedTime }}</span>
    </div>

    <div
      v-if="
        !this.hideEmptyValues ? true : this.hideEmptyValues && item.frequency
      "
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 3px"
    >
      <span>Fecuencia: </span>
      <span style="padding-left: 3px">{{ item.frequency }}</span>
    </div>
    <div
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      style="padding-top: 20px"
    >
      <span>Desarrollo: </span>
    </div>
    <div
      :class="`${off ? 'text-decoration-line-through pasive--text' : ''}`"
      v-if="!this.hideEmptyValues ? true : this.hideEmptyValues && item.comment"
      v-html="item.comment || '-'"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PhysicalActivityPrint",
  props: ["item", "off", "hideEmptyValues"],
};
</script>
