<template>
  <div class="print-container">
    <v-dialog v-model="show" fullscreen>
      <v-card class="print-card">
        <div class="pa-5">
          <div class="preview-header">
            <h3>Previsualización de la impresión</h3>
            <div class="d-flex">
              <v-switch
                v-model="showEmptyValues"
                label="Ocultar campos vacíos"
              ></v-switch>
            </div>
            <div class="d-flex">
              <v-btn @click="print" color="primary" depressed>confirmar</v-btn>
              <v-btn @click="show = false" class="ml-3" color="primary" outlined
                >Cancelar</v-btn
              >
            </div>
            <v-divider class="my-5"></v-divider>
          </div>
          <div id="printContent" style="overflow: scroll; display: block">
            <slot name="content" />
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PrintItems",
  props: ["patient"],
  data() {
    return {
      show: false,
      showEmptyValues: true,
      printCss: `
      <style>
        body {
          font-family: Arial, Helvetica, sans-serif;
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
        }

        .print-header {
          display: none;
        }

        @media print {
          .hide-on-print {
            display: none;
          }

          .print-header {
            display: block;
          }
        }
      </style>
      `,
    };
  },
  methods: {
    print() {
      const contentDiv = document.getElementById("printContent");

      let htmlContent = contentDiv.innerHTML;
      htmlContent += this.printCss;

      const newWindow = window.open("", "_blank");

      newWindow.document.write(htmlContent);
      newWindow.print();
    },
  },
  watch: {
    showEmptyValues: {
      immediate: true,
      handler(value) {
        this.$emit("hide-empty-values", value);
      },
    },
  },
};
</script>

<style scoped>
@media screen {
  .preview-header {
    display: fixed;
    top: 0;
  }
}

@media print {
  .print-container {
    overflow: scroll;
    display: block;
  }

  .preview-header {
    display: none;
  }

  .print-card {
    color: black !important;
  }
}
</style>
