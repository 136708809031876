<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <InfoItem label="Hábito" :val="item.habit" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Frecuencia" :val="item.frequency" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="caption pasive--text">Desarrollo</div>
        <div class="body-1" v-if="item.comment" v-html="item.comment"></div>
        <span v-else class="caption font-weight-medium light_secondary--text"
          >-</span
        >
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn @click="clickEdit" text icon rounded>
        <v-icon small>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-btn class="ml-1" @click="clickDelete"  text icon rounded>
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import InfoItem from "../../InfoItem.vue";

export default {
  name: "ToxicHabitInfoItem",
  props: ["formatDate", "item"],
  components: {
    InfoItem,
  },
  methods: {
    clickEdit() {
      this.$emit("click-edit", this.item);
    },
    clickDelete() {
      this.$emit("click-delete", this.item);
    },
  },
};
</script>
