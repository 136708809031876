<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="item.ageOver70"
          label="Edad superior a los 70 años (5)"
          color="primary"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="item.heartAttackInThePrevious6Months"
          label="Infarto a los 6 meses previos (10)"
          color="primary"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="item.PVYelevation"
          label="Galope diastólico PVY elevada (11)"
          color="primary"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="item.significantAorticStenosis"
          label="Estenosis aórtica significativa (3)"
          color="primary"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="item.differentRhythm"
          label="Ritmo distinto al sinusal o extrasístoles supraventriculares (7)"
          color="primary"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="item.moreThan5DifferentRhythmPerMinute"
          label="Más de cinco extrasístoles supraventriculares por minuto (7)"
          color="primary"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="item.PO2MoreThan50Mmgh"
          label="PO2 < 60 o POCO2 > 50 Mmhg (3)"
          color="primary"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="item.patientBedriddenForNonCardiacCause"
          label="K < 3.0 o CO3H > 20 UREA > 50 CREAT > 3.0 AST elevada o Hepatopatía Crónica Paciente encamado por causa no cardíaca (3)"
          color="primary"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="item.aortaSurgery"
          label="Cirugía Intraperitoneal, Intratoracica, Aorta (4)"
          color="primary"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="item.emergencySurgery"
          label="Cirugía de urgencia (5)"
          color="primary"
          hide-details
          readonly
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <p>
      Total puntos acumulados:
      <span
        :class="`${
          cardioAnalysisInterpreter(totalPoints).lightModeColor
        }--text font-weight-medium`"
      >
        {{ totalPoints }} | {{ cardioAnalysisInterpreter(totalPoints).text }}
        <v-icon
          v-if="totalPoints >= 26"
          :color="cardioAnalysisInterpreter(totalPoints).lightModeColor"
          class="ml-1 mb-1"
        >
          {{ cardioAnalysisInterpreter(totalPoints).icon }}
        </v-icon>
      </span>
    </p>
    <v-row>
      <v-col cols="12">
        <div class="caption pasive--text">Desarrollo</div>
        <div class="body-1" v-if="item.comment" v-html="item.comment"></div>
        <span v-else class="caption font-weight-medium light_secondary--text"
          >-</span
        >
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn @click="clickEdit" text icon rounded>
        <v-icon small>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-btn class="ml-1" @click="clickDelete"  text icon rounded>
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import InfoItem from "../../InfoItem.vue";

export default {
  name: "CardioAnalysisInfoItem",
  props: ["formatDate", "item"],
  components: {
    InfoItem,
  },
  computed: {
    totalPoints() {
      let points = 0;
      points += this.item.ageOver70 ? 5 : 0;
      points += this.item.heartAttackInThePrevious6Months ? 10 : 0;
      points += this.item.PVYelevation ? 11 : 0;
      points += this.item.significantAorticStenosis ? 3 : 0;
      points += this.item.differentRhythm ? 7 : 0;
      points += this.item.moreThan5DifferentRhythmPerMinute ? 7 : 0;
      points += this.item.PO2MoreThan50Mmgh ? 3 : 0;
      points += this.item.patientBedriddenForNonCardiacCause ? 3 : 0;
      points += this.item.aortaSurgery ? 4 : 0;
      points += this.item.emergencySurgery ? 5 : 0;
      return points;
    },
  },
  methods: {
    clickEdit() {
      this.$emit("click-edit", this.item);
    },
    clickDelete() {
      this.$emit("click-delete", this.item);
    },
    cardioAnalysisInterpreter(value) {
      if (value <= 5) {
        return {
          text: "Sin riesgo",
          lightModeColor: "dark_primary",
          darkModeColor: "white",
        };
      } else if (value >= 6 && value <= 12) {
        return {
          text: "Bajo riesgo",
          lightModeColor: "orange",
          darkModeColor: "yellow",
        };
      } else if (value >= 13 && value <= 25) {
        return {
          text: "Alto riesgo",
          lightModeColor: "red",
          darkModeColor: "light_error",
        };
      } else if (value >= 26) {
        return {
          text: "Muy alto riesgo",
          lightModeColor: "red",
          darkModeColor: "light_error",
          icon: "mdi-alert",
        };
      } else {
        return { text: "error", lightModeColor: "red", darkModeColor: "red" };
      }
    },
  },
};
</script>
