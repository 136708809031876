<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <InfoItem label="Diagnostico" :off="off" :val="item.pathology" />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem label="Familiar" :val="item.family" :off="off" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div>
          <div
            :class="`caption pasive--text ${
              off ? 'text-decoration-line-through' : ''
            }`"
          >
            Desarrollo
          </div>
          <div
            :class="`body-1 ${
              off ? 'text-decoration-line-through pasive--text' : ''
            }`"
            v-if="item.comment"
            v-html="item.comment"
          ></div>
          <span v-else class="caption font-weight-medium light_secondary--text"
            >-</span
          >
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!hideBtns" justify="end">
      <v-btn @click="clickEdit" text icon rounded>
        <v-icon small>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-btn class="ml-1" @click="clickDelete" text icon rounded>
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import InfoItem from "../../InfoItem.vue";

export default {
  name: "FamilyBackgroundInfoItem",
  props: ["formatDate", "item", "off", "hideBtns"],
  components: {
    InfoItem,
  },
  methods: {
    clickEdit() {
      this.$emit("click-edit", this.item);
    },
    clickDelete() {
      this.$emit("click-delete", this.item);
    },
  },
};
</script>
