<template>
  <div>
    <v-row>
      <v-col>
        <v-btn
          title="Crear un nuevo registro"
          color="primary"
          class="mt-2"
          small
          depressed
          @click="handleClickNew"
        >
          nuevo
        </v-btn>
      </v-col>
    </v-row>
    <InvoiceList ref="InvoiceListRef" :clickable="true" />
  </div>
</template>

<script>
import InvoiceList from "../Payment/InvoiceList.vue";
import RangeDateFilter from "../RangeDateFilter.vue";
import SearchInput from "../SearchInput.vue";

export default {
  name: "PatientInvoices",
  components: {
    InvoiceList,
    RangeDateFilter,
    SearchInput,
  },
  props: ["patient", "patientId"],

  methods: {
    handleClickNew() {
      this.$refs.InvoiceListRef.handleClickNew({
        choosePatient: false,
        patient: this.patient,
      });
    },
  },
  mounted() {
    this.$refs.InvoiceListRef.patientId = this.patientId;
    this.$refs.InvoiceListRef.getData();
  },
};
</script>
