<template>
  <v-form
    ref="form"
    @submit.prevent="action === 'create' ? createitem() : edititem()"
  >
    <v-row>
      <v-col md="6" cols="12">
        <v-row>
          <v-col cols="6">
            <v-combobox
              v-model="drug"
              :items="drugs"
              label="Medicamento"
              outlined
            ></v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="presentation"
              :items="presentations"
              label="Presentación"
              outlined
            ></v-combobox>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="dose"
              type="text"
              label="Dosis"
              placeholder="1000 mg"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="amount"
              type="number"
              label="Cantidad"
              hint="Ejemplo: 30"
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="daysOfUse"
              type="text"
              label="Uso"
              hint="Ejemplo: una tableta cada 8 horas"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="dark_primary" dark depressed @click="addToList">
              agregar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="6" cols="12">
        <v-card height="100%" flat>
          <div
            v-if="prescriptions.length === 0"
            class="grey--text pt-10 text-center"
          >
            No hay medicamentos agregados...
          </div>

          <div v-else v-for="(item, index) in prescriptions" :key="index">
            <div
              class="pt-5 px-5 d-flex justify-space-between text-secondary"
              v-if="!refreshingList"
            >
              <div>
                <p class="subtitle-2">
                  - <span v-if="item.drug" class="mx-1">{{ item.drug }}</span>
                  <span v-if="item.dose" class="mx-1">{{ item.dose }}</span>
                  <span v-if="item.presentation" class="mx-1">{{
                    item.presentation
                  }}</span>
                  <span v-if="item.amount" class="mx-1"
                    >(#{{ item.amount }})</span
                  >
                </p>
                <p class="subtitle-2 mt-n3" v-if="item.daysOfUse">
                  Uso: {{ item.daysOfUse }}
                </p>
              </div>
              <div class="text-right">
                <v-btn
                  small
                  icon
                  color="secondary"
                  text
                  @click="removeFromList(item)"
                >
                  <v-icon small color="secondary">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div v-else class="d-flex justify-center text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              Actualizando...
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="my-10"> </v-divider>
    <v-row>
      <v-col md="6" cols="12">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Fecha"
              readonly
              outlined
              :value="dateFormatted"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            locale="es-ES"
            color="primary"
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="permanent"
          label="Receta permanente"
          color="dark_primary"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="reference"
          label="Referencia"
          color="dark_primary"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <div class="d-flex justify-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                icon
                v-bind="attrs"
                v-on="on"
                @click="showTemplates"
              >
                <v-icon>mdi-clipboard-text-multiple</v-icon>
              </v-btn>
            </template>
            <span class="caption">Seleccionar plantilla</span>
          </v-tooltip>
        </div>
        <VueEditor
          v-model="comment"
          :placeholder="reference ? 'Referencia' : 'Observaciones'"
          :editorToolbar="[
            ['bold', 'italic', 'underline', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
            [{ color: [] }, { background: [] }],
          ]"
        />
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col>
        <v-btn
          small
          depressed
          color="primary"
          class="pa-5"
          type="submit"
          :disabled="validation"
          :loading="loading"
        >
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapMutations, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import SelectDoctorTemplate from "../../Template/SelectDoctorTemplate.vue";

export default {
  name: "MedicationForm",
  props: ["action", "patient", "item","field"],
  components: {
    VueEditor,
    SelectDoctorTemplate,
  },
  data() {
    return {
      date: moment().startOf("day").toDate().toJSON().slice(0, 10),
      dateMenu: false,
      permanent: false,
      reference: false,
      selectDrug: true,
      drug: "",
      daysOfUse: "",
      dose: "",
      amount: "",
      comment: "",
      presentation: "",
      loading: false,
      refreshingList: false,
      prescriptions: [],
      presentations: [
        "Ampolla",
        "Comprimido",
        "Cápsula",
        "Tableta",
        "Suspención",
        "Suspositorio",
        "Polvo",
        "Jarabe",
        "Gotas",
        "Inyección",
        "Óvulo",
        "Crema",
        "Gel",
        "Loción",
        "Enjuague",
      ],
      header: [
        {
          text: "Medicamento",
          value: "identificationDocument",
          align: "center",
        },
        { text: "Dosis", value: "firstName", align: "center" },
        { text: "Dias de uso", value: "lastName", align: "center" },
        { text: "Cantidad", value: "lastName", align: "center" },
      ],
    };
  },
  computed: {
    ...mapGetters(["drugs", "currentUser"]),
    validation() {
      if (this.reference) return this.comment === "";
      else return this.prescriptions.length <= 0;
    },
    dateFormatted() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
  },
  methods: {
    ...mapMutations(["setAlert"]),
    showTemplates() {
      this.$emit("show-template-list", this.field);
    },
    setTemplateContent(content) {
      this.comment = content;
    },
    addToList() {
      if (this.drug !== "") {
        this.prescriptions.push({
          drug: this.drug,
          daysOfUse: this.daysOfUse,
          dose: this.dose,
          amount: this.amount || 0,
          presentation: this.presentation,
        });
        this.cleanForm();
      }
    },
    async removeFromList(item) {
      if (item.id) {
        this.refreshingList = true;
        await this.deletePrescripton(item);
        this.refreshingList = false;
      }
      this.prescriptions = this.prescriptions.filter((p) => {
        if (p.drug !== item.drug) {
          return p;
        }
      });
    },
    async createitem() {
      this.loading = true;
      try {
        const itemToCreate = {
          prescriptions: this.prescriptions,
          comment: this.comment,
          reference: this.reference,
          permanent: this.permanent,
          patientId: this.patient.id,
          comment: this.comment,
          doctorId: this.currentUser?.doctor?.id,
          date: moment(this.date),
        };
        const res = await axios.post("/api/medication", itemToCreate);
        const { medication, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });

        this.cleanForm();
        this.reference = false;
        this.permanent = false;
        this.prescriptions = [];
        this.comment = "";
        this.$emit("created", medication);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
    async edititem() {
      this.loading = true;
      try {
        const itemToUpdate = {
          id: this.id,
          prescriptions: this.prescriptions,
          comment: this.comment,
          reference: this.reference,
          permanent: this.permanent,
          date: moment(this.date),
        };
        const res = await axios.put(`/api/medication/${this.id}`, itemToUpdate);
        const { medication, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$emit("updated", medication);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
    async deletePrescripton(item) {
      try {
        const res = await axios.delete(`/api/prescription/${item.id}`);
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
    },
    cleanForm() {
      this.drug = "";
      this.daysOfUse = "";
      this.dose = this.amount = "";
      this.presentation = "";
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(value) {
        if (value) {
          this.id = value.id;
          this.prescriptions = value.prescriptions;
          this.comment = value.comment;
          this.reference = value.reference;
          this.permanent = value.permanent;
          this.date = value.date.slice(0, 10);
        }
      },
    },
  },
};
</script>
