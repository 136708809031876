<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <InfoItem label="Motivo" :val="item.reason" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem
          label="Peso"
          :val="item.weight ? item.weight + ' ' + item.weightMeasure : ''"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Talla" :val="formatHeightLabel(item)" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="IMC" :val="imc" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Glucotest" :val="item.glucoseTest" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="FR" :val="item.FR" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="FC" :val="item.FC" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem
          label="Temparatura"
          :val="item.temperature ? item.temperature + ' °C' : ''"
        />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="TA" :val="item.TA" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Cabeza" :val="item.head" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Ojos" :val="item.eyes" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Nariz" :val="item.nose" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Oidos" :val="item.ears" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Cuello" :val="item.neck" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Torax" :val="item.chest" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Abdomen" :val="item.abdomen" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Genitales" :val="item.genitals" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Miembros superiores" :val="item.upperLimbs" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Miembros inferiores" :val="item.lowerLimbs" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Cardiopulmonar" :val="item.cardiopulmonary" />
      </v-col>
      <v-col cols="12" md="4">
        <InfoItem label="Neurológico" :val="item.neurological" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="caption pasive--text">Desarrollo</div>
        <div class="body-1" v-if="item.comment" v-html="item.comment"></div>
        <span v-else class="caption font-weight-medium light_secondary--text"
          >-</span
        >
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn @click="clickEdit" text icon rounded>
        <v-icon small>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-btn class="ml-1" @click="clickDelete" text icon rounded>
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import InfoItem from "../../InfoItem.vue";
import { convertAndCalculateImc, formatHeightLabel } from "@/helpers/common.js";

export default {
  name: "MedicalEvaluationInfoItem",
  props: ["formatDate", "item"],
  components: {
    InfoItem,
  },
  computed: {
    imc() {
      return convertAndCalculateImc(this.item);
    },
  },
  methods: {
    clickEdit() {
      this.$emit("click-edit", this.item);
    },
    formatHeightLabel(item) {
      return formatHeightLabel(item);
    },
    clickDelete() {
      this.$emit("click-delete", this.item);
    },
  },
};
</script>
