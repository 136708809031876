<template>
  <div>
    <v-row
      v-for="(item, indexItem) in itemsToPrint"
      :key="indexItem"
      align="center"
      :class="item.showPrint ? 'my-2 px-2' : 'hide-on-print my-2 px-2'"
    >
      <div class="print-header" v-if="currentUser">
        <div style="width: 100%; padding-right: 5px">
          <h3 style="padding: 0px; margin: 0px">
            {{ userPrefix }} {{ currentUser.firstName }}
            {{ currentUser.lastName }}
          </h3>
          <div style="display: flex; margin: 5px 0px">
            <div
              v-for="(s, index) in specialties"
              :key="index"
              style="font-size: 13px"
            >
              {{ s.description }}
              <span
                v-if="
                  specialties.length > 1 && specialties.length !== index + 1
                "
                style="margin-left: 5px"
              >
              </span>
            </div>
          </div>
          <div v-if="currentUser.doctor && currentUser.doctor.exequatur">
            <span>Exequatur:</span>
            <span style="padding-left: 5px">{{
              currentUser.doctor.exequatur
            }}</span>
          </div>
          <div>
            <span>
              {{ currentUser.phone }}
              <span v-if="currentUser.phone && currentUser.email"> | </span>
              {{ currentUser.email }}
            </span>
          </div>
        </div>
        <div style="padding-top: 15px">
          <span>Nombre del paciente:</span>
          <span style="padding-left: 5px"
            >{{ patient.firstName }} {{ patient.lastName }}</span
          >
        </div>
        <div>
          <span>Edad:</span>
          <span style="padding-left: 5px">{{ getAge(patient.bornDate) }}</span>
        </div>
        <div>
          <span>Sexo biológico:</span>
          <span style="padding-left: 5px">{{
            getGenderText(patient.gender)
          }}</span>
        </div>
        <div>
          <span>Grupo sanguíneo:</span>
          <span style="padding-left: 5px">{{
            patient.bloodType || "Sin definir"
          }}</span>
        </div>
      </div>
      <hr style="border: 0; height: 0; border-top: 1px solid #8c8c8c" />
      <v-col cols="12">
        <div class="my-3 d-flex">
          <v-btn
            @click="item.showPrint = !item.showPrint"
            icon
            class="mb-6 mr-2 hide-on-print"
            ><v-icon>{{
              item.showPrint ? "mdi-printer" : "mdi-printer-off"
            }}</v-icon></v-btn
          >
          <div>
            <h4 class="info-items-title">
              {{ formatInfoItemTitle(formModule, tab) }}
            </h4>
            <div class="caption">Fecha de registro: {{ date.text }}</div>
          </div>
        </div>
        <component
          :is="tab.componentNames.print"
          :item="item"
          :formatDate="formatDate"
          :off="!item.showPrint"
          :hideEmptyValues="hideEmptyValues"
          class="info-item-print"
        ></component>
        <div style="page-break-before: always"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FamilyBackgroundPrint from "./PrintInfoItems/FamilyBackgroundPrint.vue";
import PhysicalActivityPrint from "./PrintInfoItems/PhysicalActivityPrint.vue";
import AllergyPrint from "./PrintInfoItems/AllergyPrint.vue";
import CardioAnalysisPrint from "./PrintInfoItems/CardioAnalysisPrint.vue";
import DiagnosticHistoryPrint from "./PrintInfoItems/DiagnosticHistoryPrint.vue";
import DiagnosticPrint from "./PrintInfoItems/DiagnosticPrint.vue";
import GynecologyPrint from "./PrintInfoItems/GynecologyPrint.vue";
import LabResultPrint from "./PrintInfoItems/LabResultPrint.vue";
import MedicalEvaluationPrint from "./PrintInfoItems/MedicalEvaluationPrint.vue";
import MedicationPrint from "./PrintInfoItems/MedicationPrint.vue";
import SurgeryPrint from "./PrintInfoItems/SurgeryPrint.vue";
import ToxicHabitPrint from "./PrintInfoItems/ToxicHabitPrint.vue";
import PatientGeneralPrint from "./PrintInfoItems/PatientGeneralPrint.vue";
import { calculateAge } from "../../helpers/common";

export default {
  props: [
    "items",
    "formatInfoItemTitle",
    "date",
    "formModule",
    "tab",
    "formatDate",
    "patient",
    "hideEmptyValues",
  ],
  components: {
    FamilyBackgroundPrint,
    PhysicalActivityPrint,
    CardioAnalysisPrint,
    AllergyPrint,
    DiagnosticHistoryPrint,
    DiagnosticPrint,
    GynecologyPrint,
    LabResultPrint,
    MedicalEvaluationPrint,
    MedicationPrint,
    SurgeryPrint,
    ToxicHabitPrint,
    PatientGeneralPrint,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    itemsToPrint() {
      return this.items;
    },
    specialties() {
      return this.currentUser?.doctor?.specialties || [];
    },
    userPrefix() {
      const isADoctor = this.currentUser?.role === "doctor";
      if (isADoctor) {
        if (this.currentUser?.doctor?.gender === "M") {
          return "Dr.";
        } else if (this.currentUser.doctor.gender === "F") {
          return "Dra.";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    getAge(date) {
      return calculateAge(date);
    },
    getGenderText(gender) {
      if (gender === "M") {
        return "Masculino";
      }

      if (gender === "F") {
        return "Femenino";
      }

      return "Sin definir";
    },
  },
};
</script>

<style scoped>
@media screen {
  .hide-on-print {
    display: flex;
  }

  .info-item-print {
    padding-left: 45px;
  }
}

.print-header {
  display: none;
}

@media print {
  .hide-on-print {
    display: none;
  }

  .info-items-title {
    font-size: 15px;
  }

  body {
    overflow: auto;
    height: auto;
  }

  .scroll-y {
    height: auto;
    overflow: visible;
  }
}
</style>
